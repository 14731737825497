import React, {Component} from 'react'

import '../sass/components/_IntroLayer.scss'
import VideoSrcWebM from './intro-layer/baloon_intro_2.webm'
import VideoSrcMov from './intro-layer/baloon_intro_2.mov'

class IntroLayer extends Component{

  constructor(props){
    super(props)
    this.videoElem = React.createRef()
  }

  componentDidMount(){
    this.videoElem.current.addEventListener('canplaythrough',()=>{
      this.props.canPlayThrough(true)
    })
  }

  componentDidUpdate(prevProps){
    const {
      isMuted
    } = this.props
    if(prevProps.isMuted !== this.props.isMuted){
      this.videoElem.current.volume = isMuted ? 0 : 1
    }
    if(this.props.appStart){
      this.videoElem.current.play()
    }
  }

  render(){
    const {
      onVideoEnd
    } = this.props
    return (
      <video 
        ref={this.videoElem} 
        className="intro-layer-container" 
        autoPlay={false} 
        loop={false} 
        onEnded={()=>onVideoEnd()} 
        preload="auto">
        <source src={ VideoSrcWebM } type="video/webm" />
        <source src={ VideoSrcMov } type='video/mp4;' codecs="hvc1" />
      </video>
    )
  }
}

export default IntroLayer