const DataLayerData = [
  {
    colour:'#187998',
    data:[
      {
        name: "Argyll Arcade",
        address: "Argyll Arcade",
        x: 7663.898861,
        y: 2799.363925,
        desc: `
          Influenced by the Parisian Arcades of the late 18th century, the Argyll Arcade was Scotland’s first ever indoor shopping mall. It is an enclosed glass walkway with a cast iron framework and connects two of Glasgow’s main shopping streets, Argyle Street and Buchanan St.
          <br><br>In 1878 tea dealer Stuart Cranston opened the Cranston Lunch Rooms in the Arcade. Cranston’s sister, Catherine, would go on to open the famous Willow Tea Rooms designed by Charles Rennie Mackintosh and Maragret MacDonald Mackintosh.
          <br><br>Today it is the only remaining Arcade in Scotland and is home to more than 30 jewellers and diamond merchants, all under one roof.`,
        date: "1827",
        listed_category: "A",
        architect: "John Baird",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/Argyle_Arcade.jpeg'
          }
        },
        image: "Argyle_Arcade",
        image_credit: "GCHT",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Provand's Lordship",
        address: "3 Castle Street ",
        x: 11256.45337,
        y: 1505.360127,
        desc: `Provand’s Lordship is the oldest domestic building in Glasgow and one of only four surviving medieval buildings in the city. For a time it was owned by the Morton family, who used it as a sweet shop.
        <br><br>In the 19th century it was bought by the Provand’s Lordship Society with the aim of saving it and restoring it to its 1700 state. This was made possible with money and furniture donated by Sir William Burrell, the well known shipping merchant and philanthropist who donated his vast collection of antiques to the city of Glasgow, leading to the creation of the Burrell Collection museum.
        <br><br>Provand’s Lordship is now a museum, although it is currently closed.`,
        date: "1471",
        listed_category: "A",
        architect: "Not known",
        img:{
          original:{
            src:'/images/Provands_Lordship.jpg'
          },
          thumb:{
            src:'/images/thumbs/ProvandsLordship.jpeg'
          }
        },
        image: "Provands_Lordship",
        image_credit: "RonAlmog, (Flickr page), CC BY 2.0",
        image_2: "Provands_Lordship2",
        image_2_credit: "Kim Traynor, CC BY-SA 3.0",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Merchant's Steeple",
        address: "72 Clyde Street, Bridgegate (now enclosed within the Briggait",
        x: 8726.351252,
        y: 3559.586278,
        desc: `The Merchant’s Steeple is all that remains of the original Merchant’s House (or Guild Hall) and Hospital. This tall tower, with its location next to the Clyde, would have been a useful lookout for merchants awaiting the arrival of valuable cargoes from around the world.
        <br><br>The House and Hospital were demolished in 1817, with the Steeple later incorporated into the 1873 Fish Market, The Briggait.
        <br><br>The merchant’s moved to a new Merchant’s House on the corner of George Square. `,
        date: "1665",
        listed_category: "A",
        architect: "Not known",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/The_Briggait.jpeg'
          }
        },
        image: "The_Briggait",
        image_credit: "the justified sinner CC BY-NC-SA 2.0",
        image_2: "Briggait_archive",
        image_2_credit: "Glasgow City Council, Libraries Information and Learning, GC 941.435 GOR",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "St Andrew's Cathedral",
        address: "168 Clyde Street",
        x: 7674.682299,
        y: 3515.55505,
        desc: `This Cathedral is the principal church of the Roman Catholic Archdiocese of Glasgow and the seat of its Archbishop. It was built in response to increasing demand for a Catholic church in the city. Due largely to the influx of Irish Catholic immigrants to Glasgow, numbers of Catholics had risen from around 405 in 1805 to 3,000 a decade later.
        <br><br>Hostility to Catholics in Glasgow saw construction of the church sabotaged, delaying completion. Eventually guards had to be placed on the site to protect it.
        <br><br>A major conservation project began in 2009 and included the installation of a new painting of St John Ogilvie (Scotland’s only post Reformation Saint) by the renowned artist Peter Howson. `,
        date: "1817",
        listed_category: "A",
        architect: "James Gillespie Graham",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/StAndrews_Cathedrals.jpeg'
          }
        },
        image: "StAndrews_Cathedrals",
        image_credit: "Rudi Winter, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "The Trades House",
        address: "85 Glassford Street",
        x: 8965.13599,
        y: 2457.112062,
        desc: `The Trades House is the second oldest building still used for its original purpose in Glasgow, the oldest being Glasgow Cathedral.\nIt was created at the time of reform of Glasgow's local government in 1605. At that time the electorate was divided into two groups: the Merchants and the Craftsmen. Trades House was established to help protect and support the Crafts people of the City. There are 14 Incorporated Crafts, including Bakers, Weavers, Coopers, Hammermen and Barbers. Today, Trades House is a charity focusing on both educational initiatives and supporting people in need. It gives donations of more than £750,000 annually to deserving causes and individuals. `,
        date: "1799",
        listed_category: "A",
        architect: "Robert Adam",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/Trades_House.jpeg'
          }
        },
        image: "Trades_House",
        image_credit: "Reading Tom, CC BY 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Hutchesons' Hall",
        address: "158 Ingram Street",
        x: 9268.274062,
        y: 2345.340482,
        desc: `Hutcheson’s Hospital was built with monies left in the will of brothers George and Thomas Hutcheson for the purpose of building a hospital for the elderly and a school for poor boys. This new building on Ingram Street incorporated statues of the two brothers taken from the original, which was located on the Trongate. The building is owned by the National Trust for Scotland and until recently was operating as a three floor restaurant and bar. The school still exists as the fee paying Hutcheson’s Grammar School. `,
        date: "1805",
        listed_category: "A",
        architect: "David Hamilton",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/Hutchesons_Hall.jpeg'
          }
        },
        image: "Hutchesons_Hall",
        image_credit: "GCHT",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Britannia Music Hall",
        address: "109-121 Trongate",
        x: 9285.20915,
        y: 2904.19838,
        desc: `The former Britannia Music Hall is the earliest and sole surviving example of its type in Scotland. The influx of workers to Glasgow in the 18th century, who were often living in very poor conditions, found escapism in music halls with their mixture of songs, comedy and circus acts. Often music halls were attached to a public house and smoking and drinking was allowed during the performance, unlike in the bars of more high end theatres. The Britannia hosted some of the biggest names on the music hall circuit, including Stan Laurel, Harry Lauder and Vesta Tilley, a prominent male impersonator. The Britannia Panopticon charity aim to fully restore the building and ensure its future as a viable venue. They run an annual events programme. `,
        date: "1857",
        listed_category: "A",
        architect: "Gildard & MacFarlane",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/Panopticon_interior.png'
          }
        },
        image: "Panopticon_interior",
        image_credit: "Britannia Panopticon",
        image_2: "Britannia_Panopticon",
        image_2_credit: "Richard Sutcliffe, CC BY-SA 2.0",
        image_3: "Panopticon_archive",
        image_3_credit: "Panopticon Trust"
      },
      {
        name: "Corinthian Club",
        address: "191 Ingram Street",
        x: 8809.333183,
        y: 2423.241886,
        desc: `The Corinthian is notable both for being on the site of the Virginia Mansion, built in 1752 by prominent tobacco merchant George Buchanan (Buchanan Street was named after his father, a Lord Provost, Andrew Buchanan) and for its variety of uses over the years and the involvement of several prominent architects in related alterations. \n\nThe original mansion was demolished for the Glasgow and Ship Bank designed by David Hamilton. It was later altered and embellished by other notable architects including James Salmon Snr. In 1920 it was converted to judiciary courts, with many features hidden from view by false ceilings and walls. It has since been restored and these features are available to see once more. The Corinthian is now home to a variety of restaurant, bar and event spaces set over 5 floors.`,
        date: "1841",
        listed_category: "A",
        architect: "David Hamilton",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Corinthian_Club",
        image_credit: "Twospoonfuls, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Glasgow Cathedral",
        address: "Cathedral Square",
        x: 11820.39179,
        y: 1408.830126,
        desc: `The oldest building in Glasgow, this is also the only medieval Cathedral on the Scottish mainland to have survived the Protestant Reformation of 1560 intact. A new Cathedral to serve the diocese of Glasgow was begun around 1118 and consecrated in 1136, in the presence of King David I (King of Scotland 1124-1153). The Cathedral was dedicated to St Kentigern, also known as St Mungo, the patron saint of Glasgow. St Kentigern is believed to have been the first bishop of the area now known as Strathclyde. It’s thought St Kentigern was buried on the cathedral site around 612. \n\nNot all features of the Cathedral are old, for example it is noted as one of the finest post-war collections of stained glass windows to be found in Britain. \n\nThe Cathedral still has an active congregation and, unusually, is owned by the Crown. It is cared for by Historic Environment Scotland on behalf of Scottish Ministers.  `,
        date: "1118",
        listed_category: "A",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Glasgow_Cathedral",
        image_credit: "Paul Trafford, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Park Circus",
        address: "",
        x: 1845.924571,
        y: 1351.250828,
        desc: `Park Circus forms the centre piece of the Park District in the West End. It consists of two crescents of large terraced townhouses, with an oval garden in the centre. It sits atop Kelvingrove Park. \n\nNo. 22 is particularly notable. It was originally built for Walter Macfarlane, founder of the Saracen foundry, the most important manufacturer of ornamental ironwork in Scotland. The firm mass produced patterns designed by leading Glasgow architects, including Alexander ‘Greek’ Thomson. Saracen Foundry pieces can still be found across the world, including in South Africa, Australia, Canada and Brazil.\n\nAfter Macfarlane’s death his nephew, Walter Macfarlane II, took over both the firm and the house. He employed ‘Glasgow Style’ architects James Salmon and J Gaff Gillespie to modernise it. This work included a cast iron conservatory and an Art Nouveau billiards room. Wood carvings by sculptors Francis Derwent Wood, Albert Hodge and Johan Keller were also added. The house later became a regional Italian consulate and then Glasgow’s register office for civil marriages. \n\nPark Circus remains one of the most desirable addresses in Glasgow. `,
        date: "1860",
        listed_category: "A",
        architect: "Charles Wilson",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Park_Circus",
        image_credit: "David Smith CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "The Grant Arms",
        address: "186-188 Argyle Street",
        x: 6829.920913,
        y: 2888.956801,
        desc: `This building is one of only two surviving from the village of Grahamston, which vanished beneath the foundations of Glasgow Central Station when it was built in 1879. \n\nThere has been a pub on this site since 1864, and in 1908 it was remodelled in an Art Nouveau style. Over the years it has had many names. In the 1970’s it was known as ‘Gamp’s’. A nickname for an umbrella, Gamps is a character in the Charles Dickens’ novel ‘Martin Chuzzlewitt' who never went anywhere without her brolly. This is fitting given that the building is situated next to the ‘Hielanman’s Umbrella’, the railway bridge at Central Station under which highlanders who had moved to Glasgow for work would traditionally meet up. It later became ‘Da Vinci’s’ before reverting back to The Grant Arms. \n\nIn 2020 the building was given a new lease of life with an exterior refurbishment, funded by a Building Repair Grant from Glasgow City Heritage Trust.`,
        date: "c.1820",
        listed_category: "B",
        architect: "Unknown, 1908 remodelling by J H Craigie",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Grant_Arms",
        image_credit: "Neale Smith",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Trinity College",
        address: "Lynedoch Street",
        x: 2846.788261,
        y: 1457.941881,
        desc: `This building is one of the most prominent in the West End due to its three towers. \n\nThe college and an adjoining church was originally known as the Glasgow Free Church College. When The Free Church and the United Presbyterian Church amalgamated in 1900, it became the Glasgow College of the United Free Church of Scotland. \n\nThe church was destroyed by fire in 1903, with the shell later acquired for an extension to the college. From that point on the college had its three towers- the twin towers of the church and the campanile of the college. \n\nThe United Free Church later merged with the Church of Scotland and the college adopted the name Trinity College. The College combined with the University of Glasgow’s Faculty of Divinity to create an enlarged Faculty of Divinity. By the 1970s all classes were taking place at the university and the Church of Scotland decided to sell the college building. \n\nThe building is now a mix of residential and office space. `,
        date: "1856",
        listed_category: "A",
        architect: "Charles Wilson",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Trinity_College",
        image_credit: "Chris Downer",
        image_2: "Trinity College 2",
        image_2_credit: "Gordon Hatton, CC BY-SA 2.0",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Buck's Head Building",
        address: "63 Argyle Street",
        x: 8238.920219,
        y: 2949.923117,
        desc: `This commercial warehouse building by Alexander Greek Thomson is noted for its cast-iron framing and Egyptian detailing. It has a decorative cast-iron balcony at the third floor. \n\nIt was built on the site of the former Buck’s Head Hotel which opened in 1790. This building dated from the 1750s and was originally the home of Provost John Murdoch, a prominent merchant and Provost three times between 1746 and 1758. A deer statue by one of Glasgow’s best-known sculptors, John Mossman, adorns a top corner of Thomson’s building.\n\nThe building remains a city landmark and has been home to many shops. `,
        date: "1863",
        listed_category: "A",
        architect: "Alexander Greek Thomson",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Bucks_Head",
        image_credit: "Lirazelf, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Sloan's Restaurant",
        address: "62 Argyll Parade, 108 Argyle Street, Morrison Court",
        x: 7678.368812,
        y: 2821.21645,
        desc: `Sloan’s is a large bar, dining room and grand ballroom complex noted for its opulent Art Nouveau interiors. \n\nDavid Sloan, a prominent Glasgow publican of the time, bought the building in 1900, renaming it Sloan’s Arcade Cafe. He had its interior remodelled by Charles H Robinson. The results were exceptional, with features including outstanding carved timberwork, decorative plasterwork, intricate stained-glass windows and a marble fireplace. \n\nOf particular note is the entrance from the adjacent Argyll Arcade, which features a highly decorative tiled vestibule, leading to a carved timber doorway and marble staircase. Traditionally, couples would visit Sloan’s to celebrate after buying engagement rings in the Arcade, famed for its jewellery shops. \n\nSloan’s remains a popular bar, restaurant and events venue.`,
        date: "1828",
        listed_category: "A",
        architect: "Possibly John Baird",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "GCHT_Sloans",
        image_credit: "Neale Smith",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "City Hall And Bazaar",
        address: "Candleriggs/ Bell Street/ Albion Street",
        x: 9769.852157,
        y: 2516.384869,
        desc: `This complex of buildings included a bazaar (former cheese and fruit market) which was begun in 1817 as an open air walled market place. It was later roofed over, and a City Hall by George Murray opened in 1841. The main hall at Candleriggs was remodelled and a curved row of shops added by John Carrick in the 1880’s. \n\nIt was the first hall built in the city that was suitable for large gatherings and it played host to the likes of Benjamin Disraeli, Charles Dickens and William Ewart Gladstone, as well as popular and classical concerts. The Old Fruitmarket adjoins the hall and was a functioning market until the 1970s.  \n\nThe City Halls and Old Fruitmarket were reopened in 2006 after extensive refurbishment and are now home to the BBC Symphony Orchestra and the Scottish Music Centre. `,
        date: "1841",
        listed_category: "A",
        architect: "Various",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "City_Halls",
        image_credit: "Reading Tom, CC BY 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Bridge Of Sighs",
        address: "Cathedral Square",
        x: 12315.19585,
        y: 1576.487496,
        desc: `The Bridge of Sighs was built to enable funeral processions to cross the Molendinar Burn (river, now culverted) from Cathedral Square to the new Necropolis. John Strang, who proposed the bridge, descried it as “the separation between time and eternity”. \n\nIt is thought the name relates to the Ponte die Sospiri in Venice, which is a bridge connecting the cities prisons with its courts. Others say it is about the sadness felt by those crossing the bridge as part of funeral processions. \n\nThe bridge, alongside an elaborate set of iron gates and a lodge, still form an impressive entrance to the Necropolis. `,
        date: "1833",
        listed_category: "A",
        architect: "James Hamilton",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Bridge_of_Sighs",
        image_credit: "MSeses / Wikimedia Commons",
        image_2: "Bridge of Sighs 2",
        image_2_credit: "Kim Traynor, CC BY-SA 2.0",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Superintendent's House",
        address: "Cathedral Square",
        x: 11846.09392,
        y: 1547.697846,
        desc: `This 2 storey Tudor gothic style lodge forms part of the processional entrance to the Necropolis from Cathedral Square. \n\nSadly, although in fairly good condition, the House was placed on the Buildings at Risk Register for Scotland in 2010 after the last park ‘superintendent’ moved out and has remained vacant since. Discussions about potential future uses are ongoing. `,
        date: "1840",
        listed_category: "A",
        architect: "James Hamilton",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Superintendents_House",
        image_credit: "Stinglehammer, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "52 Charlotte Street",
        address: "",
        x: 10969.99286,
        y: 3273.026347,
        desc: `This symmetrical classical townhouse was one of many on Charlotte Street designed by Robert Adam, and is the only one surviving. \n\nNo. 52 was more modest than some of the other houses on the street, in particular one built for David Dale. A Scottish industrialist, merchant and philanthropist, Dale was the founder of the cotton mills at New Lanark, where the social and educational conditions he provided for his employees were far in advance of anything else available in the UK at the time. New Lanark is now recognised as a UNESCO World Heritage site. \n\nFollowing a restoration by the National Trust for Scotland, the house has been run as a guest house comprising 6 self contained flats since 1989. `,
        date: "1790",
        listed_category: "A",
        architect: "Robert Adam",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Charlotte_st",
        image_credit: "Thomas Nugent, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Portland Street Suspension Bridge",
        address: "South Portland Street",
        x: 6988.387135,
        y: 3808.029477,
        desc: `This wrought iron suspension bridge was the first purpose-built pedestrian bridge to cross the River Clyde. It links the City Centre with Laurieston and the Gorbals on the south side. The Classical pylons were designed by Alexander Kirkland and the metalwork by engineer George Martin. \n\nOriginally a halfpenny was charged to pedestrians to cross the bridge, but as there was alternative free crossings nearby the bridge was underused and the toll was soon scrapped. \n\nThe bridge has a history of structural faults. It was largely reconstructed in 1871 and further repairs were carried out in 1926. \n\nThe bridge is still well used and is now floodlit at night, making it a spectacular landmark.`,
        date: "1853",
        listed_category: "A",
        architect: "Alexander Kirkland, George Martin (engineer) ",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "South_Portland_Street",
        image_credit: "Michal Klajban, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Tolbooth Steeple",
        address: "High Street",
        x: 10125.94871,
        y: 2746.369978,
        desc: `The seven-storey Tolbooth Steeple is all that remains of a much larger building, the Tolbooth, which at one time contained the Town’s Clerk Office, the council hall and the city prison. \n\nThe Tolbooth would have been a busy spot, with prisoners being dealt with, stagecoaches from Edinburgh and London stopping and bringing visitors as well as gossip, and proclamations being read out from a special platform (important in the days before general literacy could be relied on for getting messages across). It would have been the place to be and be seen. \n\nThe council later moved to Jail Square in the Saltmarket and then to the City Chambers in George Square. As the city expanded and moved Westwards, The Tolbooth became less of a focal point and was demolished in 1921. The Steeple now stands isolated in the middle of a road. `,
        date: "1634",
        listed_category: "A",
        architect: "John Boyd",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Tolbooth_Steeple",
        image_credit: "Stevie Spiers, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Ramshorn Theatre",
        address: "98 Ingram Street",
        x: 9803.301859,
        y: 2320.476138,
        desc: `The Ramshorn is an important early example of Scottish Gothic Revival architecture. It is the only Scottish church by Thomas Rickman, a self-taught architect known for Gothic detailing. The building is noted for its fine stonework and stained glass windows depicting stories from the Old and New Testament. \n\nKnown as the Ramshorn Kirk, the church was in a wealthy area of Glasgow at the time. As such, many prominent Glaswegians are buried in the adjacent Ramshorn Cemetery, including David Dale (founder of New Lanark cotton mills) and tobacco merchants Andrew Buchanan and John Glassford. It’s also the resting place of Pierre Emile L’Angelier, the victim in the notorious Madeleine Smith murder case. Accused of murdering her lover by arsenic poisoning, the case against Madeleine was found to be ‘not proven’ at trial. \n\nThe Church was sold to the University of Strathclyde in the 1980s and converted into a theatre by Page/Park architects in the early 1990s. It later became offices and exhibition space.  `,
        date: "1826",
        listed_category: "A",
        architect: "Thomas Rickman",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Ramshorn",
        image_credit: "Becky Williamson, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Tobacco Merchants House",
        address: "42 Miller Street",
        x: 8518.585681,
        y: 2640.325429,
        desc: `The Tobacco Merchant’s House is the last of the Georgian villas known as the Virginia Tobacco Merchants’ houses to remain standing in the Merchant City. The wealth of many of these merchants was built on slavery. \nIt was built by Baillie John Craig for himself. The land had previously been owned by John Miller, namesake for the street. Miller decreed strict regulations on property built on the street, including the design and style of buildings, and the businesses permitted to use them. \n\nThe building earns its name from its time as the home of Robert Findlay, a Glasgow tobacco merchant. As cash from his business was stored in the house, it once had an iron door and some of the original iron safes inside still exist today.\n\nA restoration of the house was initiated by Glasgow Building Preservation Trust in the 1990s and saw many of its original Georgian features uncovered as Victorian additions were removed. The building is now offices and is owned and occupied by the Scottish Civic Trust.  `,
        date: "1775",
        listed_category: "A",
        architect: "John Craig",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "TMH",
        image_credit: "Novoje, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Tron Steeple",
        address: "71 Trongate",
        x: 9750.105266,
        y: 2855.231693,
        desc: `The Tron Church was founded in 1525, with the clock tower (steeple) erected in the late 16th century and the spire added in the early 17th century. The church was destroyed by fire in 1793, with only the steeple surviving. \n\nThe west-facing wall of the steeple features a sculpture of St Mungo, patron saint of Glasgow. It was designed by Sharmanka, a local kinetic sculpture company, in 2001. The sculpture is activated hourly when the steeple bell tolls. A bird pecks, a fish spins and St Mungo raises his staff. The bell, bird and fish are all symbols of Glasgow and feature on its Coat of Arms. \n\nThe Church was redeveloped in the 1980s as the Tron Theatre. `,
        date: "1636",
        listed_category: "A",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Tron_steeple",
        image_credit: "Postdlf, CC BY-SA 3.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Tron Kirk",
        address: "38 Parnie Street",
        x: 9819.270591,
        y: 2940.527353,
        desc: `The Tron Kirk was built as a replacement for the original 16th century church, which was destroyed by fire in 1793. \n\nThe fire was caused by a notorious group called the Hellfire Club. There were Hellfire Clubs across Europe at the time, members were men of high standing who met as an excuse for debauchery and to carry out acts deemed too immoral for polite company.\n\nThe story goes that after a night of drinking the group happened across the night watchmen’s fire and decided to see how much heat each could withstand. After stoking up a huge fire, they could no longer endure the heat and fled. The building was set alight and destroyed, with only the steeple surviving. The current church was built a year later, separately from the tower. \n\nThe Church is now the popular Tron Theatre.`,
        date: "1794",
        listed_category: "A",
        architect: "James Adam",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Tron_Theatre",
        image_credit: "Kim Traynor, CC BY-SA 3.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Royal Exchange",
        address: "46-58 Royal Exchange Square",
        x: 8184.7951,
        y: 2333.969691,
        desc: `The Royal Exchange was commissioned as a mansion for tobacco merchant William Cunninghame. It has had numerous uses since, including as a bank and a library. \n\nOriginally a home, it was acquired by the Royal Bank of Scotland in 1817 and was later converted to an Exchange by architect David Hamilton. The Exchange was a meeting place for merchants and other businessmen to make deals and engage services like shipping and insurance. Many of those who used the Exchange, including founder James Ewing, owned or profited from the labour of slaves on sugar and tobacco plantations in the American colonies and West Indies. One of the city’s first telephone exchanges was installed in the building in 1880. Ironically, the need for such a meeting place declined once telephones became commonplace.\n\nThe building is now the Gallery of Modern Art (GoMA). In recent years the gallery has investigated the history of the building in a variety of ways. It has not shied away from telling the story of its links to the slave trade and the part this play’s in the wider narrative of the city’s history. `,
        date: "1778",
        listed_category: "A",
        architect: "Unknown, alterations by David Hamilton",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Goma",
        image_credit: "Neale Smith",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Statue Of Duke Of Wellington",
        address: "Queen Street",
        x: 8259.478837,
        y: 2375.121954,
        desc: `This bronze equestrian statue is of Arthur Wellesley, 1st Duke of Wellington, best known for defeating Napoleon at the Battle of Waterloo.\n\nThe statue has become an icon of Glasgow and is popular with tourists due to there usually being a traffic cone on the Duke’s head. The story goes that this practice began in the 1980’s, when a drunken student climbed atop the statue and placed a cone there. Every time it was removed a new cone appeared in a matter of days. Due to minor damage and the potential for injury when placing a cone, the practice was discouraged by the city council and the police. Lonely Planet included the statue in its list of the ‘top 10 most bizarre monuments on Earth’ in 2011. \n\nGlasgow City Council put forward plans in 2013 to double the height of the plinth to stop people climbing up, as part of a £65,000 restoration project. This was met with widespread opposition, led by a Facebook campaign called ‘Keep the Cone’. An online petition against the plans got 10,000 signatures!`,
        date: "1844",
        listed_category: "A",
        architect: "Carlo Marochetti",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "wellington",
        image_credit: "tup wanders, CC BY 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Gardner's Warehouse",
        address: "36 Jamaica Street",
        x: 6683.499566,
        y: 3201.215537,
        desc: `A landmark in architectural history, the construction of Gardner's Warehouse was the first time in which the lessons learned from prefabricating the structure of the Crystal Palace were successfully applied to everyday building. The Crystal Palace was an impressive cast iron and plate glass structure designed by Sir Joseph Paxton and built in Hyde Park, London, for the Great Exhibition of 1851. \n\nThe structural frame was designed by Robert McConnell, who patented its wrought and cast iron beams. \n\nIt was built as a furniture warehouse for A Gardner & Son, who were upholsterers and cabinetmakers. The company was renowned for the craftsmanship of their work. They held several patents for the treatment and preservation of timber. \n\nThe building is now the Crystal Palace pub. `,
        date: "1856",
        listed_category: "A",
        architect: "John Baird and Robert McConnell",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Gardners_Warehouse",
        image_credit: "Chris Allen, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "St Vincent Crescent",
        address: "",
        x: 288.0658436,
        y: 2391.887691,
        desc: `This classical terraced crescent is one of the longest in the UK and is striking in its uniformity of design, style and building materials. \n\nOriginally part of the Stobcross Estate, in 1849 Alexander Kirkland was commissioned to design the new suburb of Stobcross. St Vincent Crescent was the first and only part of the development ever to be built, with plans to extend westwards and southwards never materialising. Kirkland himself lived in no.39. \n\nBy the 1930s St Vincent Crescent had begun to deteriorate, with commercial and industrial development in the area causing many middle class families to move away. By the 1950s demolition was even considered. However, by the 1970s the growth of the conservation movement led to more interest in the restoration of historic properties and today St Vincent Crescent is a desirable, if often overlooked, address. `,
        date: "1855",
        listed_category: "A ",
        architect: "Alexander Kirkland",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Stvincent",
        image_credit: "Richard Sutcliffe, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Glasgow Necropolis",
        address: "Castle Street",
        x: 12994.97028,
        y: 1396.612581,
        desc: `Inspired by the Parisian cemetery Pere Lachaise, the Necropolis was one of Scotland’s first planned garden cemeteries. Set on a prominent hill with panoramic views over Glasgow, it became a favourite place to visit, take a stroll and get some fresh air. It hosts more than 50,000 burials and 3,500 monuments by famous architects and sculptors including Alexander Greek Thomson and David Hamilton.\n\nGravestones include that of Corlinda Lee, Queen of the Gypsies. Born in 1831, Corlinda merged two gypsy dynasties when she married horse trainer George Smith and they became King and Queen of their extended family. George capitalised on the Victorian fascination with gypsies by hosting ‘gypsy balls’ where the public could come and see how they lived. Its rumoured that Queen Victoria attended one and Corlinda read her palm. It is custom for passers by to leave a coin at Corlinda’s memorial for good luck. \n\nThe Necropolis is still a popular place to visit for both locals and tourists alike. `,
        date: "1833",
        listed_category: "",
        architect: "Various",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "necropolis",
        image_credit: "dkloi, CC BY-NC-ND 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Park Church Tower",
        address: "Lynedoch Place",
        x: 2679.469593,
        y: 1388.991791,
        desc: `This distinctive Gothic style tower, along with the Trinity College towers next to it, dominates the skyline of the local area.\nIt was originally part of the Park Church, which was demolished in 1968 and replaced by an office building. `,
        date: "1858",
        listed_category: "B",
        architect: "J. T. Rochead",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "park_church",
        image_credit: "Chris Downer ",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "The Glasgow Society Of Lady Artists' Club",
        address: "5 Blythswood Square",
        x: 5816.186557,
        y: 1841.666688,
        desc: `The Glasgow Society of Lady Artist’s was established in 1882 and originally met at 136 Wellington Street. The aim of the society was ‘the study of Art, to be promoted by means of life classes and monthly meetings at which members will be required to exhibit sketches, and by an annual exhibition of members' work’. \n\nBy 1895 the society had purchased 5 Blythswood Square and in 1907 a Decoration Committee commissioned Charles Rennie Mackintosh to carry out some interior alterations. His designs included a striking pedimented door case for the entrance which is still in place today. \n\nThe property was sold to the Scottish Arts Council in 1971. `,
        date: "1829",
        listed_category: "B",
        architect: "Additions by Charles Rennie Mackintosh",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Society_of_Lady_Artists",
        image_credit: "Sara Thomas, CC BY-SA 4.0",
        image_2: "5_Blythswood",
        image_2_credit: "Lirazelf, CC BY-SA 3.0 ",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Royal Scottish Automobile Club",
        address: "8-13 Blythswood Square",
        x: 5936.595031,
        y: 1882.818952,
        desc: `These buildings, originally homes, became the headquarters of the Royal Scottish Automobile Club (RSAC) after they gradually bought up the entire length of one side of Blythswood Square. By 1926 work by James Miller to completely remodel them was complete.\n\nThe RSAC was established in 1899 to promote ‘automobilism’ in Scotland. Their headquarters were grand and included a ballroom. In the 1950s-60s the Square became a regular starting point for the long distance Monte Carlo rally. Crowds would gather in the Square to wave goodbye to the drivers as they set off for the French Riviera. \n\nBy 2001 RSAC membership had fallen and financial difficulties led to the headquarters being closed in 2002. The building reopened in 2009 as the luxurious 5-star Blythswood Square Hotel. `,
        date: "1823",
        listed_category: "B",
        architect: "James Gillespie Graham. Facades John Brash. Later alterations by James Miller",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Blythswood Square",
        address: "",
        x: 5720.164609,
        y: 1888.915583,
        desc: `Blythswood Square was the centrepiece of the ‘Magnificent New Town of Blythswood’, a westward expansion from around 1800 onwards on land stretching from Buchanan Street to the River Kelvin. The land on which the square sits was owned by William Harley, a textile manufacturer and builder.\n\n7 Blythswood Square was the home of Madeleine Smith, the defendant in a sensational 1850s murder case. Accused of poisoning her lover Pierre Emile L’Angelier with arsenic when he threatened to show her father their love letters after she became engaged to a more eligible man, the trial was reported in great detail around the world. The jury eventually reached a ‘not proven’ verdict. Madeleine later moved to London and the New York, where shew died aged 92 in 1928. \n\nBlythswood Square is now home to a 5-star hotel and an assortment of businesses. `,
        date: "1823",
        listed_category: "B",
        architect: "James Gillespie Graham and John Brash",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Automobile_club",
        image_credit: "Partick Camera Club, 1005.97.266 / OG.1955.121.[226]",
        image_2: "Blythswood",
        image_2_credit: "Thomas Nugent, CC BY-SA 2.0",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Carlton Place",
        address: "",
        x: 6590.057692,
        y: 4193.612378,
        desc: `Carlton Place was intended as the showpiece river frontage of Laurieston, a high class residential suburb built by brothers John and David Laurie. \n\nSituated on the South of the River Clyde, Carlton Place consisted of two terraced townhouses, each 375 feet in length. They were designed as a single architectural concept, an attempt at a whole street in a symmetrical architectural composition. The centrepiece of the Eastern Terrace was Laurieston House at no. 50-53. Home to the Laurie brothers, it is a rare surviving example of a Georgian townhouse. Its interior features ornate decorative plasterwork of outstanding quality. It is thought to be the work of  Francisco Bernasconi, who was brought to Britain by George III to decorate Windsor Castle. \n\nThe success of these new suburbs was limited as rapidly growing industry and the accompanying influx of workers led to decay and overcrowding, with those who could afford it moving West. \n\nSadly, Laurieston House has now been on the Buildings at Risk Register for Scotland for many years.`,
        date: "1804",
        listed_category: "A/B",
        architect: "Peter Nicholson",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Carlton_Place",
        image_credit: "Michal Klajban, CC BY-SA 4.0",
        image_2: "Carlton_Place2",
        image_2_credit: "Thomas Nugent, CC BY-SA 2.0",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Claremont Terrace",
        address: "",
        x: 2250.902765,
        y: 1546.926903,
        desc: `This symmetrically arranged classical terrace was built in 1847, with No. 6 having been originally built as a freestanding mansion. \n\nNo. 6 was the home of Isabella Elder for 36 years after her husband died. John Elder was a marine engineer who invented the compound engine. This engine consumed far less coal than others of the time, enabling ships to use fuel more efficiently and therefore travel further. Isabella was a well known philanthropist. After her husband’s death, she presented Elder Park to the people of Govan, remarking “my heartfelt wish is that, to all who enter it, it may prove a blessing”. Isabella also funded the building of a library in the park, stipulating that it had to be open on Sundays so that working people could access it on their day off. Perhaps her  greatest achievement though was her support for the development of medical training for women, which resulted in the first female graduates in medicine in Scotland in 1894. She was awarded an honorary degree from the University of Glasgow in 1901.   \n\nAnother well known resident of the terrace was Sir James King, 1st Baronet, who lived at no. 12. He was Director of the Clydesdale Bank for over 40 years and was also Chairman of the Caledonian Railway Company. He served as Lord Provost from 1886-89 and was knighted by Queen Victoria after an official visit in 1887. In 1888 he oversaw the Glasgow International Exhibition and the opening of the new City Chambers in George Square and was raised to the rank of Baronet. He left the property in the early 1900’s and it became a nursing home. \n\nClaremont Terrace is still one of Glasgow’s most prominent addresses.`,
        date: "1847",
        listed_category: "A",
        architect: "John Baird",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Claremont",
        image_credit: "Paul Harrop, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "St Andrew's Parish Church",
        address: "1 St Andrews Square",
        x: 10473.71804,
        y: 3255.819148,
        desc: `The design of this classical church heralded a new style for large scale churches in Scotland. It was inspired by St Martin-in-the-Fields, London (1722). \n\nThe Church has played a significant role in many episodes of Scottish history. In 1745 the Jacobite army, led by Bonnie Prince Charlie, camped within its semi-built walls on their return from battles in England (filming for historical drama series Outlander took place at the church in 2018). In 1776 Agnes Maclehose married James Maclehose at the church. Agnes is better known as Clarinda, whose love letters to Robert Burns under the name Sylvander inspired ‘Ae Fond Kiss’. In 1785, crowds gathered to watch Vincenzo Lunardi take off from the church yard in a hot air balloon, on a flight that would take him to Hawick. \n\nThe Church was acquired by the Glasgow Building Preservation Trust in 1993 for £1. After renovation the building reopened on St. Andrews Day 2000 and was operated by the St Andrews in the Square Trust (SAINTS) as a wedding and events venue. Sadly the venue ceased trading in early 2020.`,
        date: "1756",
        listed_category: "A",
        architect: "Alan Dreghorn. Alterations in 1874 by John Carrick",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "SAINTS",
        image_credit: "Colin, CC BY-SA-4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Forth & Clyde Canal",
        address: "",
        x: 7213.314653,
        y: 1353.151839,
        desc: `The Forth & Clyde Canal is the world's first man-made sea-to-sea ship canal, started in 1768 and opened in 1790. It runs from Grangemouth in the east to Bowling in the west; a distance of 56 km (34.5 miles).\n\nBuilding a canal through the narrowest part of Scotland meant avoiding the risky and time-consuming sail around the north coast. Cargoes of timber, coal, clay and sand were transported along the canal, and there was also a regular passenger service. The advent of steam brought paddle steamers and puffers to the canal.  \nAlfred Barnard’s description of the Forth & Clyde Canal at Port Dundas is worth quoting in full. “Port Dundas...is situated, strange to say, at the top of a hill overlooking the city. The appearance of ships’ masts in such a position, over-topping the houses, presented to us a peculiar surprise. The canal, which is a direct water-way from the Clyde to the Forth, a distance of same thirty-seven miles, over the whole of its progress through bustling towns and quiet villages, commands fine views of the country, pretty water scenes, and the magnificent background of the Forth. Port Dundas itself, however, is the scene of great commercial activity, and the prominent feature of the locality is the Distillery.”\n\nThe canal closed to navigation on Hogmanay 1962/63 and reopened in 2001 as part of the Millennium Link project, restored at a cost of £84.5 million.`,
        date: "1790",
        listed_category: "Scheduled monument",
        architect: "John Smeaton",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Canal",
        image_credit: "Gemma Wild",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      }
    ]
  },
  {
    colour:'#e5193d',
    data:[
      {
        name: "Old College",
        address: "High Street",
        x: 10785.67392,
        y: 2243.852825,
        desc: `In 1460 James, Lord Hamilton granted a building and some land on the east side of High Street to the University for a College. The Old College site was subsequently extended and developed by the University, and ran along the busy High Street, with a main gateway in its centre that led through to two courtyards. It remained the home of the University until the move to Gilmorehill in the city's west end in 1870. The Old College was called \"One of the finest, and certainly (the) most extensive specimen of Scottish civil architecture of the 17th century\" by the 19th century Scottish architects MacGibbon & Ross. But the old buildings were sold to a railway company, demolished and replaced with a goods station. The Lion and Unicorn staircase was moved brick by brick to the University's new campus at Gilmorehill, and now leads into the west wing of the Main building, beside the University Chapel. Other parts of old college buildings were combined to create Pearce Lodge, at the bottom of University Avenue.`,
        date: "1460",
        architect: "Various",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Old_College",
        image_credit: "Reproduced with the permission of Glasgow City Council, Libraries Information and Learning, Mitchell Library, FA 3/2",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "The closes and wynds",
        address: "High Street",
        x: 10396.1669,
        y: 2243.852825,
        desc: `The history of Glasgow starts in the High Street, but by 1864, these dingy and narrow closes and wynds led to rows of slum houses packed onto land that had once been the gardens and backlands of street-fronting houses on the High Street. \n\nA famous series of photographs taken by Thomas Annan in 1868 depicts the reality of this area. The worst of Glasgow's slums and their back wynds and closes were demolished by the City Improvement Trust during the final three decades of the 19th century.`,
        date: "-",
        architect: "Various",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Closes",
        image_credit: "Annan Photographs Glasgow",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Bridge Street railway station",
        address: "",
        x: 5496.327956,
        y: 4288.863408,
        desc: `Bridge Street Station was built for the Glasgow and Paisley Joint Railway and was the first true passenger station in Glasgow and a product of the railway optimism of the early 1840s. The booking hall, on Bridge Street, was described by George Cunningham as 'a place like a church with large columns in front’. The station proved highly successful - in 1847, it handled over one million passengers!\n\nAfter the opening of a new station in 1890, the booking hall was converted for use as offices. The original portico was removed c.1950 and the remainder of the buildings were demolished in 1971. The original site was never redeveloped and is now landscaped.`,
        date: "1841",
        architect: "James Collie",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Bridge_St",
        image_credit: "Nigel Thompson, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Tennant's Stalk",
        address: "Castle Street, St Rollox",
        x: 8047.796487,
        y: 1469.652978,
        desc: `In 1800 Charles Tennant founded a chemical works at St. Rollox. The principle product was bleaching powder, which was sold worldwide.\n\nThe St. Rollox plant grew to be the largest chemical works in the world and with a payroll of over one thousand people it dominated the local economy.\n\nThe works' enormous chimney, known as 'Tennant's Stalk', was, at 455 feet, the tallest chimney in the world. It was a well-known landmark around Glasgow. It was in daily use until 1922, when it was struck by lightning and had to be demolished.\n\nThe site of the chimney today is next to Springburn Road, near the car showrooms at Douglas Park.`,
        date: "1842",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Grahamston",
        address: "",
        x: 6760.071134,
        y: 2655.254508,
        desc: `Grahamston was a peripheral village which emerged in 1680, however, by the mid 18th century, Glasgow was flourishing, and benefiting from its advantageous position beside the Clyde, many warehouses set up in Grahamston. \n\nGrahamston was also the site of Glasgow’s first theatre, with the Alston Theatre constructed in 1764. Although technically outside Glasgow, (the boundary remained at Union Street until 1830), the theatre was ‘outsourced’ to nearby Alston Street due to continuing religious opposition. In 1780 a fire gutted the theatre and threatened to consume the surrounding buildings. Eyewitness accounts state the magistrate directed the firefighters to ‘save the other folks’ hooses an’ let the Devil’s hoose burn!’ \n\nJust as the industrial revolution transformed and allowed Grahamston to prosper, Glasgow’s continuing advancement also led to its demise. In the 1870s Central Station was built over Grahamston and acted as the main terminus for trains run by the Caledonian Railway Company.\n\nThere are few, if any, pictures which show Grahamston, and those which exist are often its demolition. Urban fables of Victorian streets and shops, stuck in time beneath the maze of tunnels of Central Station are unfortunately just that. Grahamston was (almost) razed entirely to the ground to accommodate the station. The Grant Arms on Argyle Street is perhaps the most striking remnant of the village.`,
        date: "1680",
        architect: "Various",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Sailors’ Home",
        address: "Broomielaw",
        x: 4736.688215,
        y: 3466.00151,
        desc: `A call for a sailor’s home was first made in 1853 as there was concern for the lack of suitable lodgings for the large number of seamen who required a bed in Glasgow between voyages. The 4-storey building had a six-storey circular tower on the corner of James Watt Street, which became a well-known landmark. This featured a time ball atop the tower- Glasgow’s answer to the one o'clock gun. A now obselete time-signalling device, a time ball was dropped at a predetermined time, principally to enable navigators aboard ships offshore to verify the setting of their marine chronometers. The ‘Ball’ was removed some time before the building’s demise. The Institute closed in 1971 and it was later demolished. The site has since been redeveloped for offices.`,
        date: "1856",
        architect: "J T Rochead",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Sailors_Home",
        image_credit: "Prof John Hume, https://canmore.org.uk/collection/718013",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Glasgow Night Asylum for the Houseless",
        address: "North Frederick Street",
        x: 9094.606263,
        y: 1885.508917,
        desc: `The Night Asylum for the Houseless was a charitable institution set up in 1838 for the purpose of providing shelter for the homeless. Originally based on St. Enochs Wynd, it could cater for up to 100 people. \n\nAs the homeless population in the city increased the need for an additional site became urgent. In 1847 the North Frederick Street Night Asylum shown on this map opened. It was partially demolished in 1958 to make way for the Glasgow College of Building & Printing, now Category B-Listed and a prominent city centre landmark.`,
        date: "1847",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Night_Asylum",
        image_credit: "Glasgow Caledonian University, Heatherbank Museum of Social Work, print 6185c",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Andersonian University",
        address: "George Street",
        x: 9547.745838,
        y: 2074.795068,
        desc: `The \"Andersonian\" was originally a technical school for the teaching of practical scientific and industrial subjects. It was founded in 1796 with money bequeathed by John Anderson, a professor at the University of Glasgow. When more space was needed plans were drawn up for this site on George Street. At the same time, the name of the institution was changed to Anderson’s University. Construction of the new building was completed in 1830.\n\nAnderson's University was amalagamated in 1887 with a number of other institituions to form the West of Scotland Technical College, which became today's University of Strathclyde.\n\nThe building was demolished in 1902 to make way for the Royal Technical College building.`,
        date: "1830",
        architect: "Robert Scott",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Anderson_College",
        image_credit: "Source unknown",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Fire Station",
        address: "College Street",
        x: 10207.3794,
        y: 2198.117864,
        desc: `The Central Fire Engine Station was purpose-built to designs by the City Architect and housed the service's headquarters for 49 years until it moved to Ingram Street. \n\nBefore this the service had been based in the police office in South Albion Street, with access to the engine house through the Dog and Bird Market, where rails were laid to allow the engines to be wheeled more easily along the cobbled street.\n\nAt College Street the first modern fire station lay-out in Glasgow was created. Each engine occupied its own bay, which opened directly onto the street. It was also the first station to provide centralised stabling for horses, which could be harnessed to the engines and be out on the street within two minutes.`,
        date: "1851",
        architect: "John Carrick",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Duke Street prison",
        address: "Duke Street",
        x: 11938.16588,
        y: 1798.04636,
        desc: `Duke Street Prison opened in 1798 and was an early example of the 'separate system', a form of prison management based on the principle of keeping prisoners in solitary confinement. Common features of a separate system prison include a central hall, with several radiating wings of prison blocks, as can be seen on Sulman’s map. \n\nIt held both male and female prisoners until the opening of Barlinnie Prison in 1882, after which it functioned as a women’s prison. Many suffragettes and female political activists were imprisoned here, including suffragettes Ethel Moorhead and Dorothea Chalmers Smith, and the protests at the living conditions contributed towards the move to close the prison in 1955.\n\nThe building was demolished in 1958 and the Ladywell housing scheme was built on the site in 1964. The only remaining structure is some of the boundary wall.`,
        date: "1798",
        architect: "J Paterson and J Herbertson",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Duke_St_Prison",
        image_credit: "Glasgow Caledonian University, Heatherbank Museum of Social Work, print 4010",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Fishmarket",
        address: "Glasgow Green",
        x: 9547.739909,
        y: 3993.33552,
        desc: `The Glasgow Fish Market was located at the Broomielaw for nearly a hundred years, from 1755 to 1853, after which it was transferred up river to Glasgow Green, between Albert Bridge and the Weir. In 1867 it moved again, to Great Clyde Street, with a grand French Renaissance style frontage facing the Clyde designed by architects Clarke and Bell. The cast iron galleried, glass-roofed market hall within served as the city’s fish market for over 100 years, until 1977.`,
        date: "1853",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Central Police Office",
        address: "South Albion Street",
        x: 9908.843228,
        y: 2593.897998,
        desc: `In the 17th century, Scottish cities used to hire watchmen to guard the streets at night. In November 1800 the Glasgow Police, newly formed under the Glasgow Police Act of that year, gathered in the Session House of the Laigh Kirk, Trongate, for the first time.\n\nIt is sometimes described as the first modern-style municipal police force, although as well as policing they also fought fires, called the hours and swept the streets, similar to the older city watchmen. The Central Police Office was built to designs by the City Architect John Carrick. \n\nThe Old Central Police Office in South Albion Street closed in 1906 and a new Central Police Office opened in Turnbull Street. The existing city bazaar was extended onto the site of the old offices in 1907 to designs by JW Houston, and is now part of Merchant Square.`,
        date: "1825",
        architect: "John Carrick",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Police_Office",
        image_credit: "Unknown source",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "City Poorhouse",
        address: "Parliamentary Road",
        x: 9111.814095,
        y: 1552.824166,
        desc: `Originally built as an insane asylum and opened in 1809, the Poorhouse was renowned as being one of the largest in the UK. Poverty was seen as a ‘dishonourable’ state and under the New Poor Law of 1834, assistance was not to be offered to those unwilling to enter a workhouse. \n\nPoorhouses became so widespread that it is estimated that at times of severe economic depression 6.5% of the British population may have been in workhouses at any one time. Run as a mixture of hospital and prison (note how similar the poorhouse and the prison look!), conditions were terrible and the requirements to work your way out of the poorhouse made it virtually impossible.\n\nThe state of the building led to its closure in 1905. The site is now occupied by Glasgow Caledonian University at the junction of North Hanover Street and Cowcaddens Road.`,
        date: "1809",
        architect: "William Stark",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Poorhouse",
        image_credit: "Glasgow University Library, MS Murray 636",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Theatre Royal",
        address: "Dunlop Street",
        x: 8205.012783,
        y: 3112.896799,
        desc: `The Theatre Royal in Dunlop Street was opened in 1782 by John Jackson.  When Jackson took over the new Theatre Royal, in Queen Street, the Dunlop Street Theatre was renamed the Caledonian, with part serving as a warehouse.\n\nIn the 1820s its cellar was occupied by a rival showman, and on nights when a quiet drama was planned for one theatre the other would hire a brass band!\n\nThe theatre shown on Sulman’s map was one rebuilt by architect William Spence after a fire in 1840. It was rebuilt in 1863 to Spence’s original designs after another fire. In 1869 it was sold to the railway companies and demolished to make way for St Enoch's station. The site is now occupied by the St Enoch Shopping Centre.`,
        date: "1840",
        architect: "William Spence",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Theatre_Royal",
        image_credit: "Source unknown",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Parry’s Music Hall",
        address: "Greendyke Street",
        x: 10246.98016,
        y: 3477.616797,
        desc: `Comedian George Parry, a pantomimist for many years in the Theatre Royal, took over the Queen's Theatre in Greendyke Street in 1854. Over the years he was fined annually by the magistrates for performing plays without a licence, the Glasgow Herald observing : “the house was opened as usual the same night and goes on as before, crowded with the most abandoned of youth of both sexes. As the Queen's pays astonishingly well, it is more convenient to be fined than licensed.”\n\nBy 1890 the building had been demolished and replaced with the Hide, Wool & Tallow Market. These buildings, designed by Honeyman and Keppie, were converted to flats in 2000 and are Category B-Listed.`,
        date: "1849",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Model Lodging House",
        address: "Greendyke Street",
        x: 10423.53252,
        y: 3486.909026,
        desc: `In the 1860s, most 'common' lodging houses were privately-run and practically unregulated, and \"... besides being hotbeds of vice and misery, were also centres for the propagation of disease. In contrast the philanthropic Glasgow Model Lodging Houses Association aimed to provide “comfortable and cheap accommodation for the working classes, including strangers in the city in search of employment, or in course of transit to other places.”\n\nThe model lodging house's main attraction was that it allowed its ‘lodgers' to retain a degree of independence. For this reason it was a much better option (by those who could afford it) to the poorhouse. \n\nA new municipal lodging house was constructed on this site by the Town Council in 1874. The ‘new’ Greendyke model was demolished in 1962 and the site is now occupied by modern flats.`,
        date: "1852",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Glasgow Barracks",
        address: "Gallowgate",
        x: 12636.28459,
        y: 2387.404015,
        desc: `Before the construction of the barracks, soldiers were lodged with Glasgow’s residents. The new buildings could accommodate 1,000 men but by the mid-19th century, the barracks were in a poor condition and such was the spread of disease due to soldiers sleeping with local prostitutes that certain wards of the Royal Infirmary were used only to treat the military. This was given as one of the reasons for the re-location of the barracks - the University had already moved from the High Street for similar reasons. In 1872 new barracks were opened on Maryhill Road. In 1889 the dilapidated barracks were sold to a railway company for use as a goods yard. The site is now occupied by a supermarket.`,
        date: "1795",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Barracks",
        image_credit: "Reproduced with the permission of Glasgow City Council, Libraries Information and Learning, Mitchell Library, GC 941.435 GOR",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Lock Hospital",
        address: "41 Rottenrow",
        x: 11058.9,
        y: 1673.278989,
        desc: `The Glasgow Lock Hospital was established in 1805 for women suffering from venereal disease and moved to this location in 1846. At that time venereal disease was at near epidemic proportions in Glasgow, but the social customs of the time meant that when this was constructed in the city’s red light district it was made to look just like one of the surrounding tenements rather than a hospital.\n\nIts patients were the poorest and most desperate women and children in Glasgow, and for many of them winding up in the Lock was a fate worse than death. The conditions were horrendous, and it used mercury as a treatment - which was ultimately toxic to the patient. The Lock finally closed its doors in 1947 and the building was demolished in 1955. The site is now occupied by University of Strathclyde student halls.`,
        date: "1846",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Lock_Hospital",
        image_credit: "Source unknown",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "National Bank of Scotland",
        address: "Queen Street",
        x: 8047.044885,
        y: 2625.054763,
        desc: `The Glasgow office of the Edinburgh-based National Bank of Scotland was designed by the architect John Gibson of London firm Gibson & Macdowal and opened on Queen Street in 1847. It was in a mixed Italian style. The semicircular tops to the groundfloor front windows are carved to represent the five principal British rivers, the Thames, Clyde, Tweed, Severn and Humber.\nIn 1898, the building was purchased by Hunter, Barr & Company, on the proviso that it remain a bank for four years.\n\nAfter the four years, the building was taken down and re-erected by Glasgow Corporation as Langside Hall, near Queens Park, in 1901. Sadly, when the building was reconfigured, it lost its beautiful central banking hall in order to create smaller, more useable spaces.`,
        date: "1847",
        architect: "John Gibson ",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "National_Bank",
        image_credit: "Reproduced with the permission of Glasgow City Council, Libraries Information and Learning, Mitchell Library GC 914.14353 WIL (1850)",
        image_2: "Langside Halls",
        image_2_credit: "Past Glasgow, CC BY-NC-ND 2.0",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Tillie & Henderson Warehouse",
        address: "37-51 Miller Street",
        x: 8367.626794,
        y: 2638.993107,
        desc: `Alexander Kirkland’s finest Glasgow building, the exuberant Venetian-style Tillie & Henderson warehouse at 37-51 Miller Street was one of the city's greatest losses in the demolition derby of the 1960s and 70s. It had an open courtyard to the street. Tillie & Henderson were established wholesale shirt, corset and underclothing makers.\n\nIn the 1960s the building was seen as unfashionable and was threatened with demolition. There were attempts to preserve the building and Professor David Walker has described the offer of a grant for repairs as the “first breakthrough on Victorian architecture with the Historic Buildings Council.” Sadly, the building was subsequently flooded and was demolished in 1969. A large modern retail store, fronting Argyle Street, now occupies the site.`,
        date: "1854",
        architect: "Alexander Kirkland",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "37-51_Miller_Street",
        image_credit: "Prof John Hume, http://canmore.org.uk/collection/710766",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "St Matthew’s Free Church",
        address: "357 Bath Street",
        x: 3972.141207,
        y: 1859.697169,
        desc: `The church was designed by Salmon & Black and built for a Free Church congregation. It had the tallest spire in the city and was one of the largest churches.\n\nThe building was gutted by fire in 1952. The site was sold to the flamboyant businessman A.E. Pickard, who was criticised for a long delay in demolishing the ruins of the building. A.E. Pickard was a business genius, millionaire and eccentric, with an outrageously zany sense of humour. He was thirty when he moved to Glasgow with an ambition to own property. Thinking his career lay in the entertainment business, he bought the old Britannia Music Hall in the Trongate, re-naming it The Panopticon. In the 1960s the only landlord who had more property than him was Glasgow Corporation.\n\nThe site is now on the edge of the M8 motorway and is occupied by the Elmbank Gardens complex.`,
        date: "1859 - 1861",
        architect: "Salmon & Black",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Lowry",
        image_credit: "St Matthew's Church' by TS Lowry",
        image_2: "St Matthews",
        image_2_credit: "Partick Camera Club, 1005.97.353 / OG.1955.121.[275]",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Elgin Place Church",
        address: "Corner of Pitt St & Bath St",
        x: 5274.200491,
        y: 1773.658009,
        desc: `Elgin Place Congregational Church was built in the Greek Revival style, with its large pillars at the front giving it the appearance of a classical temple. The building stopped being used as a church in 1962. It avoided the stone cleaning trend that swept through Glasgow in the 1970s and retained its dark, sooty exterior. One of its most impressive internal features, a pipe organ built in 1903 by Henry Willis & Sons, was moved to St Andrew's Cathedral in 1981.\nIn 1982 the building became Cardinal Follies nightclub and quickly became a popular destination for clubbers. It was later renamed The Temple, and in November 2000 the club was relaunched as the Shack, with two floors and five bars.\nIn December 2004, the former church was severely damaged by fire and demolished.`,
        date: "1856",
        architect: "John Burnet Snr",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Elgin_Place",
        image_credit: "Annan Photographs Glasgow",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "St Enoch Church",
        address: "St Enoch Square",
        x: 7173.575336,
        y: 3184.911575,
        desc: `St Enoch’s Square is one of six squares in the city centre. Enoch is a corruption of Thenew or Theneu, who was the mother of Glasgow's patron saint St Mongo or Kentigern. The church was built on a site supposed to be on or near her burial place. \n\nThe church was rebuilt in 1827, retaining the old spire, to designs by David Hamilton, as seen on Sulman’s map. \n\nAfter Sulman’s map was published many streets of houses, shops, warehouses inns, and theatres, on the east side of the square were demolished to make way for the railway lines of the Glasgow & South Western Railway Company crossing the Clyde. St. Enoch Station opened in 1876, with its St. Enoch Hotel opening in 1879. \n\nThe church was demolished in 1925 in order to allow space for a bus terminus and car parking and the congregation moved to St Enoch's Hogganfield.`,
        date: "1827-8 ",
        architect: "David Hamliton",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "St_Enoch",
        image_credit: "Reproduced with the permission of Glasgow City Council, Glasgow Museums, 1340.82.387",
        image_2: "St Enoch demolition",
        image_2_credit: "Source unknown",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Dundashill Distillery",
        address: "High Craighall Road",
        x: 7303.239969,
        y: 1304.378786,
        desc: `Dundashill Distillery was established by John Harvey in 1770 and stood by Craighall Road on the banks of the Forth & Clyde Canal. \n\nDundashill was the second distillery visited by Alfred Barnard when he set out on his epic mid-1880s adventure to see at first hand every whisky-making distillery in Britain, ultimately leading to the publication in 1887 of his 'Whisky Distilleries of the United Kingdom' (Port Dundas was first). Barnard describes Harvey as, “one of the first three licensed Distillers in Scotland.” And says, “Some of the buildings in connection with the distillery are of a great height, the top of one of them forming the highest point in Glasgow, and from which a splendid view can be obtained.”\n\nAfter that the owner changed several times and in 1902 it was closed and in 1903 it was demolished. The site was taken over by Diageo who announced its closure in 2009. It is now due for redevelopment into housing.`,
        date: "1770",
        architect: "Unknown",
        img:{
          original:{
            src:'/images/Argyle_Arcade.jpg'
          },
          thumb:{
            src:'/images/thumbs/.jpeg'
          }
        },
        image: "Port_Dundas",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      }
    ]
  },
  {
    colour:'#27ae60',
    data:[
      {
        name: "The Briggait",
        address: "141 Bridgegate",
        x: "8681.959679",
        y: 3634.411702,
        desc: `The Briggait was the city’s fish market for over 100 years. It’s been described by Historic Environment Scotland as ‘Scotland’s most important collection of surviving market halls’. Features included a sculpted portrait of Queen Victoria, flanked by seahorses and medallion heads representing Neptune and the River Clyde. When the market moved in 1977 the galleried cast iron, glass roofed hall was converted into a shopping development. Sadly, it did not thrive and the building then lay mostly empty for nearly 20 years. \n\nThe Wasps Trust and Wasps Artists’ Studios have since redeveloped the building to create affordable workspaces.`,
        date: 1873,
        listing_category: "A",
        architect: "Clarke & Bell",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "The_Briggait",
        image_credit: "the justified sinner CC BY-NC-SA 2.0",
        image_2: "Glasgow City Council, Libraries descrmation and Learning, GC 941.435 GOR",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "City Chambers",
        address: "George Square",
        x: "9046.144621",
        y: 2169.001943,
        desc: `In October 1889 Glasgow's Lord Provost, James King, hoped that the new Municipal Buildings would, ‘promote with zeal and perseverance the highest and best interests of this great community and of the empire to which it belongs.’ In other words, a proud city needed a proud town hall. The expansion of Glasgow’s population meant the Town Council's responsibilities constantly grew. To accommodate these, new municipal buildings had been built three times already in the 19th century, most recently in 1870–74.\nA powerful statement of Victorian civic pride and wealth, the Beaux Arts style City Chambers occupies the whole block between George Square and John Street, its 4 facades all equally rich in detail. Victorian Glasgow was and is widely imagined and presented as the proud ‘Second City of the Empire’, and the building's architectural style, sculpture and inauguration ceremonies created an image of ‘imperial’ Glasgow which emphasised loyalty to Union and empire. For example, the City Chambers comes complete with a pediment featuring Queen Victoria which celebrates Britain's imperial subjugation of other countries. \nWhat fed the City’s identification with empire was a significant and complicated economic relationship with it, that started in the 18th century with the trade in tobacco and other products grown by slaves in the Americas and the Caribbean. However, a recent study has revealed that the City Chambers itself was not built with wealth accumulated from the slave trade, but was funded via Glasgow Corporation’s enormous municipal income. Nevertheless, the building serves as a microcosm of Victorian Imperialism with materials sourced from across the Empire.`,
        date: 1888,
        listing_category: "A",
        architect: "William Young",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Glasgow_City_Chambers_2",
        image_credit: "Neale Smith",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: "",
        isSpecial:true,
      },
      {
        name: "Queen Street Station",
        address: "George Square",
        x: "8488.356479",
        y: 2036.547514,
        desc: `The original station was opened at Queen Street in 1842 by the Edinburgh and Glasgow Railway. The 1878 cast-iron and glass, arched train shed shows the impact of the Victorian railway boom in Glasgow, when the various railway companies competed to build bigger and better than their rivals. The train shed was designed by the civil engineer James Carswell, who also introduced electric lighting at the station, which was one of the earliest examples of electricity use in Glasgow.\n\nOther arched sheds of note are found at St Pancras, Manchester, and Charing Cross, London, but Queens Street is the only remaining large single span overall station in Scotland.\n\nFollowing demolition of the surrounding buildings in 2018, the Victorian train shed was uncovered for the first time in more than 40 years. Subsequently redevelopment work on the station has integrated the historic structure into the new station building. `,
        date: 1878,
        listing_category: "A",
        architect: "James Carswell",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Queen_St2",
        image_credit: "Glasgow City Council, Libraries descrmation and Learning, D-PL 2/1/1868",
        image_2: "Maccoinnich, CC BY-SA 3.0",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Horse Shoe Bar",
        address: "17-19 Drury Street",
        x: "7298.927508",
        y: 2351.108381,
        desc: `17-19 Drury Street is a fine Classical building with elements that indicate the influence of Alexander 'Greek' Thomson.\n\nA bar opened on this site as far back as 1846 when William Turnbull, a local spirits dealer, moved in to the premises. It was taken over by John Scoullar in 1884, who renamed it The Horse Shoe Bar, in keeping with the equine names of his other bars, The Snaffle Bit and the Spur Bar.\n\nThe Horseshoe has an outstanding pub interior with an island bar.  Many publicans visited it and borrowed ideas from the design which they used to enhance their own pubs and so the island bar layout became de rigeur in Glasgow for higher class pubs from the 1890s. It enabled quick service and better supervision of customers. The interior has undergone little alteration in the last 100 years.\n\nThe bar has attracted many famous names, including the band Travis who used the upstairs lounge as a rehearsal room before they hit the big time. `,
        date: 1870,
        listing_category: "A",
        architect: "Unknown",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Horseshoe_Bar",
        image_credit: "Adam Bruderer, CC BY 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Garnethill Synagogue",
        address: "129 Hill Street",
        x: "4679.633927",
        y: 1619.136252,
        desc: `Garnethill Synagogue is of great architectural and historical significance as it is the first purpose built synagogue in Scotland. It has been described as the finest example of high Victorian synagogue architecture north of Liverpool.\n\nThe building's exterior is Romanesque revival and it has extensive high quality stained glass windows featuring richly coloured floral patterns predominantly by J B Bennett and Sons. \n\nThe Jewish community in Glasgow was established in the 1820s. By the 1870s, the community numbered around 1,000 and looked to build a permanent synagogue for the first time in Scotland as the converted synagogue in George Street (opened 1858) was full. It was believed that this location was popular because many of the Jewish community were moving to the west of the city. From the 1880s, a new wave of immigrant Jews from Eastern Europe, fleeing poverty and persecution, settled in Glasgow - mostly in the Gorbals area. `,
        date: 1879,
        listing_category: "A",
        architect: "John McLeod",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Garnethill_Synagogue",
        image_credit: "Robert Burdock CC BY-NC-ND 2.0",
        image_2: "Stinglehammer, CC BY-SA 4.0",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Scottish Temperance League Offices",
        address: "106-108 Hope Street",
        x: "6790.874874",
        y: 2332.291617,
        desc: `The  Scottish Temperance League was founded in Glasgow in 1844 . The League believed in the power of propaganda and education to change people’s attitudes rather than legislative prohibition.  The League occupied offices first here at 108 Hope Street, Glasgow, and later at 226 West George Street, Glasgow.\n\nThe temperance movement, led by middle-class social reformers who wanted to manage an unruly working class, advocated drinking alcohol in moderation. They tried to convince working men to spend their wages on clothes, food, and middle-class comforts, rather than on spirits. Temperance rhetoric argued that spending money on alcohol would only lead to your ruin and the ruin of your family. \n\nThis has been described as a \"little orange-red Franco-Flemish\" building, with \"light-hearted details\" but it is five storeys high, so it is only small in comparison with the neighbouring buildings. Rather curiously, in view of its austere purpose, it stands out because of its fanciful, imported style, and all its sculptural embellishments.`,
        date: 1894,
        listing_category: "A",
        architect: "W Forrest Salmon",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Scottish_Temperance_League_Offices",
        image_credit: "Stinglehammer, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Theatre Royal",
        address: "Hope Street",
        x: "7370.431212",
        y: 1562.68596,
        desc: `The Theatre Royal has an exceptional Victorian auditorium with fine plasterwork. It is the oldest theatre in Glasgow and the longest running in Scotland. \n\nThe first theatre on the site, the Royal Colosseum, was built in 1867 by the architectural practice Clarke & Bell. In 1869 the theatre was leased to Glover & Francis who previously ran the old Theatre Royal in Dunlop Street, which had been demolished to make way for St Enoch railway station. William Glover brought the name Theatre Royal with him and his company of artistes, orchestra and stage staff, presenting drama, opera, revues and pantomime.\n\nA fire in 1879 destroyed the interior and the auditorium was rebuilt to the classical French Renaissance design seen today by the celebrated theatre architect, C J Phipps. The auditorium suffered another fire only fifteen years later but was reconstructed by Phipps largely to his previous design.\n\nToday the theatre is home to Scotland's resident companies, Scottish Opera and Scottish Ballet.`,
        date: 1867,
        listing_category: "A",
        architect: "CJ Phipps",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Theatre_Royal",
        image_credit: "Stinglehammer, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Glasgow School Of Art",
        address: "Renfrew Street",
        x: "5710.792607",
        y: 1621.017929,
        desc: `Widely regarded as Charles Rennie Mackintosh's masterpiece. The eponymous and highly original Mackintosh Building was designed by Charles Rennie Mackintosh in two phases between 1896–1909 and has been recognised since the 1930s as a work of international importance as well as one of Glasgow’s iconic landmarks. The building contains studios and workshops for teaching; a library and museum; accommodation for the Headmaster (Director); professors' studios; and a variety of secondary spaces for administrative and other purposes.\n\nThe 'Description and Schedule of Contents' that accompanied the designs stated that Mackintosh had tried 'to make the building express the purpose for which it is intended by a frank acceptance of the requirements and a moulding of these into such forms as they think has produced a result which is pleasing in proportion and in which the useless expenditure of money on mere embellishments has played no part.' This is a principle of the Arts & Crafts movement - form follows function - that how a building is used internally should determine what it looks like externally. \n\nA major fire in 2014 damaged much of the western half of the Glasgow School of Art, with the total loss of the Mackintosh Library. The Mackintosh Library was built between 1907 and 1909, and is located at first floor level. As well as notable items of furniture and important books, the library featured elaborate structural timberwork and bespoke fittings.\n\nA further fire in 2018 left the building as a burnt-out shell. Salvage work is ongoing. `,
        date: 1897,
        listing_category: "A",
        architect: "CR Mackintosh",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "GSA",
        image_credit: "Jörg Bittner Unna, CC BY 3.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Willow Tea Rooms",
        address: "217 Sauchiehall Street",
        x: "6224.490271",
        y: 1773.433719,
        desc: `The Willow Tea Rooms first opened in 1903 and are the only surviving tearooms designed by Charles Rennie Mackintosh and Margaret Macdonald for local entrepreneur Miss Catherine Cranston.\n\nThey quickly gained enormous popularity, and are the most famous of the many Glasgow tearooms that opened in the late 19th and early 20th century. The temperance movement was becoming increasingly popular in Glasgow at the turn of the century and Miss Cranston had conceived the idea of a series of \"art tearooms\", venues where people could meet to relax and enjoy non-alcoholic refreshments in a variety of different \"rooms\" within the same building.\n\nThe Willow Tea Rooms, as they became known, was the fourth of Cranston’s tearooms with which Mackintosh was involved. Here Mackintosh was responsible for the exterior for the first time, as well as for the interior arrangement and decoration. The name of the tearooms is reflected in the decorative motif chosen by Mackintosh, which was derived from the meaning of ‘Sauchiehall’: alley of willows. The jewel in the crown of the tearooms is the 'Room de Luxe', and customers had to pay an extra penny for the privilege of enjoying their tea in that room!\n\nThe building was fully restored, largely to Mackintosh's original designs, between 2014 and 2018. `,
        date: 1903,
        listing_category: "A",
        architect: "CR Mackintosh",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Mackintosh_at_the_Willow",
        image_credit: "Mark Barbieri",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Egyptian Halls",
        address: "Union Street",
        x: "7144.630042",
        y: 2610.779727,
        desc: `Work started on the Egyptian Halls in March 1870 to provide new commercial premises for James Robertson, an iron manufacturer, and was completed in 1872. Built using cast iron and stone, the Egyptian Halls was one of the last major projects of architect Alexander 'Greek' Thomson. \n\nThe building broke many of the rules of the time; thick stone columns normally found at ground level were on the top floor. The building is built on four storeys. The ground floor was occupied by shops with fully glazed wide bays. The first floor features eighteen window bays divided by square columns with a flowing scroll capital. On the second floor, shorter couple columns are positioned exactly above the first floor columns, again above these columns is a decorative entablature with a Roman-style decoration. Finally, on the third floor is a plinth with dwarf columns and pseudo-Egyptian lotus flower capitals. Behind these columns is a continuous glazed screen, which is not fixed to these columns. Topping these columns is another entablature with a cornice. This 'attic' room is lit by a series of sloping skylights.\n\nThe building has been described as one of the finest in Glasgow and, according to the secretary of the Royal Incorporation of Architects in Scotland, is the finest surviving example of an Alexander Thomson commercial building and is of international importance. It has been protected as a category A listed building since 1966.`,
        date: 1873,
        listing_category: "A",
        architect: "Alexander Thomson",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Egyptian_halls",
        image_credit: "The JR James Archive",
        image_2: "Scott Abercrombie, CC BY-NC-SA 2.0",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Stewart Memorial Fountain",
        address: "Kelvingrove Park",
        x: "892.2579527",
        y: 1670.650008,
        desc: `The fountain commemorates the contribution of Lord Provost Robert Stewart of Murdostoun to the campaign to pass the Loch Katrine Act, 1855, which paved the way to a fresh water supply for Glasgow and consequent improvements in public health.\nBuilt of granite, sandstone, marble and bronze, this flamboyant French-Scottish Gothic structure commemorates the event with imagery of the Trossachs taken from Sir Walter Scott's narrative poem, \"The Lady of the Lake.\" \n\nThe fountain was the subject of a major restoration scheme in 2008 (with funding from Glasgow City Heritage Trust) to celebrate the 150th anniversary of the Loch Katrine water supply.`,
        date: 1872,
        listing_category: "A",
        architect: "James Sellars (sculptor: John Mossman)",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Stewart_Fountain",
        image_credit: "",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "General Post Office",
        address: "George Square",
        x: "8792.857209",
        y: 2273.774827,
        desc: `Built in response to regular complaints about inadequate facilities for postal services in Glasgow, the General Post Office on the south side of George Square reflected the growing commercial status of the city when it opened in 1878.\n\nThe building occupies half an acre and incorporated modern developments such as the use of electricity. Various alterations and extensions were made before the building ceased to be used as a post office in 1995.\n\nIt was converted into apartments, offices, and retail in 2002. `,
        date: 1878,
        listing_category: "A",
        architect: "Robert Matheson",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "GPO",
        image_credit: "Andy Farrington, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Merchants' House",
        address: "George Square",
        x: "8382.135294",
        y: 2139.227993,
        desc: `Given the importance of trade in Glasgow it is not surprising that the Merchants' House, founded in the 17th century, became one of the most important bodies in the city. It campaigned for the deepening of the Clyde, was instrumental in founding the Necropolis and was active in support of British rule in the 18th century Revolutionary War, to protect the tobacco interests of its members.  The Merchant’s House has recognised that a number of those holding office, or who left funds to the House, profited from slavery during this time. \n\nThe original Merchants' Hall was in Bridgegate and was demolished around 1818 leaving only the steeple.\n\nThey moved to the current Merchants' House in 1874. It incorporates some panels and carved stone probably salvaged from the earlier building. The building is topped by a domed tower on which is perched a ship on a globe, a reminder of the importance of sea trade to Glasgow's prosperity.`,
        date: 1878,
        listing_category: "A",
        architect: "JJ Burnet",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Merchants_House",
        image_credit: "dave souza, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Stirling's Library",
        address: "48-54 Miller Street",
        x: "8532.675046",
        y: 2595.902,
        desc: `Walter Stirling was a prosperous merchant, the son of a surgeon and the nephew of a Lord Provost. Known as \"Humphy Watty\" because of a deformity of his spine, when he died unmarried in 1791 he left his 804 books, his house in Miller Street and £1000 to establish a free reference library, which was named Stirling's Library. Initially the books were for reference only and available only to subscribers.\nAfter the house was demolished in the early 1860s the collection of 20,000 books was housed in a new building erected on the same site to an Italian Renaissance design. In 1912 the library was placed in the care of Glasgow Corporation's library service and moved to new premises in Miller Street. It moved back and forth between Miller Street and GOMA during the 20th century until 2002 when it was permanently housed in the Library at GOMA. `,
        date: 1865,
        listing_category: "A",
        architect: "James Smith",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "48-54_Miller_Street",
        image_credit: "Twospoonfuls, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "King's Theatre",
        address: "Bath St and Elmbank Street",
        x: "4494.37919",
        y: 1865.433113,
        desc: `The Kings Theatre is an important example of an Edwardian theatre by the renowned theatre architect Frank Matcham. \n\nThe imposing street elevation was designed to attract an audience and was built in high quality materials in a style compatible with any large civic building, now that in the Edwardian period theatregoing was a respectable entertainment. Comfort for the audience was a priority as was maximising audience numbers. At the King's in Glasgow there is a lavish marble lined entrance foyer with a barrel-vaulted coffered ceiling and this leads onto the highly decorative auditorium.\n\nIt has played host to top stars over the years including Sir John Gielgud, Katharine Hepburn, and Lord Laurence Olivier.\n\nThe theatre continues to present first class musicals and pantomime alongside a wider range of drama, dance and comedy.`,
        date: 1903,
        listing_category: "A",
        architect: "Frank Matcham",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "King_s_Theatre",
        image_credit: "Stinglehammer, CC BY-SA 4.0",
        image_2: "Jimmy Baikovicius, CC BY-SA 2.0",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Lion Chambers",
        address: "170-172 Hope Street",
        x: "7122.049925",
        y: 1878.807599,
        desc: `The Lion Chambers is an 8-storey commercial building of Glasgow-style Art Nouveau design. The building was commissioned by William G. Black, a lawyer and member of the Glasgow Arts Club, which included some well-known artists such as James Abbott McNeill Whistler. The lower floors were intended as lawyers' offices and the upper floor as artists' studios. The building is the second redescrced concrete structure in Scotland and amongst the first few in Britain. The structural engineer was LG Mouchel, a Yorkshire Hennebique contractor. The Hennebique system was created by French engineer, Francoise Hennebique and featured redescrced concrete as an alternative to steel frames, making the building fireproof. \n\nThe building features stone work on the outside showing a lion's head, referencing the name – \"Lion Chambers\", as well as the upper bodies of some Judges of the court. The building was home to many lawyers and artists for most of the 20th century. The main bulk of the building had to be evacuated in 1995 when a decision was made that it was too dangerous to regularly use. The ground floor and basement were still used up until 2009 when it was decided that they too should be vacated.  \n\nThe castle-like building has been on the Buildings at Risk Register for Scotland since 1997, with complex ownership, as well as the building's innovative mode of construction, making repair and reuse very challenging. `,
        date: 1905,
        listing_category: "A",
        architect: "James Salmon Jnr",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "The_Lion_Chambers",
        image_credit: "Lirazelf, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Stock Exchange",
        address: "63-69 Nelson Mandela Place",
        x: "7870.957141",
        y: 2168.585768,
        desc: `The Glasgow Stock Exchange had been founded in 1844 and it was to be one of five exchanges, the others being in Aberdeen, Edinburgh, Dundee and Greenock.\n\nThe current building was designed by John Burnet in a Venetian Gothic style, believed to have been inspired by the Royal Courts of Justice. Burnet was the first Glasgow architect to use sculpture on a grand scale after David Hamilton , and often employed the Mossmans and their former pupils John Crawford and James Young to carve the statues, busts and other ornaments on his buildings. It was later extended by his son JJ Burnet.\n\nThe entire building was remodelled between 1969 and 1971. The interiors were demolished but the intricate facade was retained. It now houses shops and restaurants. `,
        date: 1877,
        listing_category: "A",
        architect: "John Burnet",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Stock_Exchange",
        image_credit: "David Smith, CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Pavilion Theatre",
        address: "125 Renfield Street",
        x: "7522.9576124061405",
        y: 1730.638429,
        desc: `One of Glasgow's oldest theatres, the Pavilion Theatre of Varieties opened on 29 February 1904 as a music hall. The building has remained relatively unchanged in layout since then. \n\nThe theatre was designed by respected Theatre Architect Bertie Crewe and was regarded as luxurious for its time. The owners, Glasgow Pavilion Ltd, described its decor as \"pure Louis XV\", featuring Rococo plasterwork across the proscenium arch and boxes, terrazzo flooring, leadlight glazing and rich mahogany wood finishing. Its main elevation is in salmon-pink terracotta, treated in an ornate manner in the style of the Later French Renaissance.\n\nThe Pavilion is still in use today and has stayed close to it roots, producing and providing a traditional venue for comedy, music, variety and pantomimes. `,
        date: 1904,
        listing_category: "A",
        architect: "Bertie Crewe",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "pavilion",
        image_credit: "Stinglehammer, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "The Hatrack",
        address: "142-144 St Vincent Street",
        x: "6766.413081",
        y: 2211.864326,
        desc: `Officially named St Vincent Chambers, the Hatrack is an example of a pioneering  ‘elevator building’ and demonstrates a highly imaginative approach to squeezing a lot into a tight space, in this case cramming ten storeys onto a site less than thirty feet wide. \n\nThe light, elegant front is mostly glass surrounded by a bare minimum of decorative red sandstone which makes it recognisable as a Glasgow building of the Victorian period. \n\nIts popular name was inspired by the cupola, which has projecting finials that resemble the \"pegs\" of a hat rack. The building is used as offices. `,
        date: 1902,
        listing_category: "A",
        architect: "James Salmon Jnr",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "hatrack",
        image_credit: "Neale Smith",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Ca D'Oro Building",
        address: "Gordon Street",
        x: "7186.026923",
        y: 2458.363937,
        desc: `Inspired by the Ca’ d’Oro in Venice, the Ca' d’Oro Building is of a Venetian Renaissance style. \n\nThe building was constructed as F and J Smith’s Furniture Warehouse. It was built using triple-arched cast iron frames, which were cast at the Saracen Iron Foundry, with masonry arches above the shops below. Above the arches, large bay and round windows are framed by Doric and Corinthian columns.\n\nBetween 1926 and 1929, the building was expanded and partially revised according to a design by Gillespie Kidd & Coia. The building became known as the Ca' D'Oro in 1927, when a restaurant of that name was opened in the concrete mansard which had been newly erected on top.\n\nA fire devastated the building in 1987, although the cast iron frame survived. When it was rebuilt, the interior was replaced by an atrium surrounded by shops.`,
        date: 1872,
        listing_category: "A",
        architect: "John Honeyman",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "Ca_D_oro",
        image_credit: "Thomas Nugent , CC BY-SA 2.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      },
      {
        name: "Bell O'The Brae Tenements",
        address: "High Street",
        x: "11019.0018",
        y: 1844.937423,
        desc: `The Bell o'the Brae was the old name for the stretch of High Street leading up from George Street to Drygate. It is said to have taken its name from a \"deid bell\" that had been installed in a little turret at the top of the hill since medieval times, and which tolled at funerals.\n\nIts old buildings had degenerated into slums by the end of the 19th century and they were swept away by the City Improvement Trust and replaced with modern tenements, 1899-1902. At the same time the street's steep incline was slightly reduced during the re-alignment and improvement of the area's streets.\n\nThe red sandstone tenements have Scottish Renaissance detailing and shops at ground floor. The backs of the building are built in cheaper yellow (Mugdock) sandstone. `,
        date: 1901,
        listing_category: "B",
        architect: "William Boston",
        img:{
           original:{
             src:'/images/Argyle_Arcade.jpg'
           },
           thumb:{
             src:'/images/thumbs/.jpeg'
           }
         },
        image: "High_Street",
        image_credit: "Marcok, CC BY-SA 4.0",
        image_2: "",
        image_2_credit: "",
        image_3: "",
        image_3_credit: ""
      }
    ]
  }
]

export default DataLayerData