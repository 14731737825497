import React, {Component, Fragment, useState} from 'react'

import queryString from 'qs'
import InitData from '../../data/InitData'
import Icon from '../Icons'
import Loader from '../loading-screen/Loader'
import '../../sass/components/side-bar/_AdminModeSidebar.scss'

class AdminPinDataSidebar extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      id:null,
      imageLoading:true,
      imageCredit:null,
      title:null,
      desc:null,
      email:null,
      newsletter:false,
      x:null,
      y:null
    }
  }

  componentDidMount(){
    const {
      data
    } = this.props
    this.setState({
      id:data.id,
      image:data.image ? data.image : '',
      imageCredit:data.image_credit ? data.image_credit : '',
      title:data.name ? data.name : '',
      desc:data.desc ? data.desc : '',
      email:data.user_email ? data.user_email : '',
      newsletter:data.newsletter ? data.newsletter : '',
      x:data.x,
      y:data.y
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      const {
        data
      } = this.props
      this.setState({
        id:data.id,
        image:data.image ? data.image : '',
        imageCredit:data.image_credit ? data.image_credit : '',
        title:data.name ? data.name : '',
        desc:data.desc ? data.desc : '',
        email:data.user_email ? data.user_email : '',
        newsletter:data.newsletter ? data.newsletter : '',
        x:data.x,
        y:data.y
      })
    }
  }

  updateInput(e,input){
    this.setState({
      [input]:e.target.value
    })
  }

  imageLoaded(){
    this.setState({
      imageLoading:false
    })
  }

  deletePin(){
    const query = queryString.parse(window.location.search)
    const {
      id
    } = this.state
    fetch(`${InitData.api}/admin/delete/${query['?admin']}`,{
      method:'POST',
      body:JSON.stringify({
        id:id
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res.json())
    .then(res => {
      if(res.updated){
        this.props.closeSidebar()
        this.props.updateAdminLayer()
      }
    })
    .catch(err => console.log(err))
  }

  submitPin(){
    const query = queryString.parse(window.location.search)
    const {
      id,
      image,
      imageCredit,
      title,
      desc,
      email,
      newsletter,
      x,
      y
    } = this.state
    //send pin details
    const requestObject = {
      method:'POST' ,
      body:JSON.stringify({
        id:id,
        image:image,
        imageCredit:imageCredit,
        title:title,
        desc:desc,
        email:email,
        newsletter:newsletter,
        x:x,
        y:y
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }

    fetch(`${InitData.api}/admin/add/${query['?admin']}`, requestObject)
      .then(res => res.json())
      .then((res) => {
        fetch(`${InitData.api}/admin/delete/${query['?admin']}`,{
          method:'POST',
          body:JSON.stringify({
            id:id
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(res => res.json())
        .then(res => {
          if(res.updated){
            this.props.closeSidebar()
            this.props.updateAdminLayer()
          }
        })
      })
      .catch(err => console.log(err))
  }

  render(){

    const {
      data
    } = this.props

    const {
      imageCredit,
      title,
      desc,
      email,
      newsletter
    } = this.state
    return (
      <div className={`admin-mode-wrapper`}>
        {
          data.image && 
          <div className="admin-mode-image">
            {
              this.state.imageLoading && 
              <div className="image-loader"><Loader/></div>
            }
            <img 
              onLoad={()=>this.imageLoaded()}
              src={`https://s3.eu-west-1.amazonaws.com/uploads.gallusglasgow.co.uk/images/${data.image}`}/>
          </div>
        }
        <div className="admin-mode-input admin-mode-name">
          <span>Image Credit</span>
          <input type="text" value={imageCredit} onChange={(e)=>this.updateInput(e,'imageCredit')}/>
        </div>
        <div className="admin-mode-input admin-mode-name">
          <span>Pin title</span>
          <input type="text" value={title} onChange={(e)=>this.updateInput(e,'title')}/>
        </div>
        <div className="admin-mode-input admin-mode-desc">
          <span>Description</span>
          <textarea value={desc} onChange={(e)=>this.updateInput(e,'desc')}/>
        </div>
        <div className="admin-mode-input admin-mode-name">
          <span>Email address</span>
          <input type="text" value={email} onChange={(e)=>this.updateInput(e,'email')}/>
        </div>
        <div className="admin-mode-input admin-mode-name">
          <span>Newsletter?</span>
          <span>{newsletter ? 'yes' : 'no'}</span>
        </div>
        <div className="button-wrapper delete">
          <button onClick={()=>this.deletePin()}>Delete Pin</button>
        </div>
        <div className="button-wrapper add">
          <button onClick={()=>this.submitPin()}>Add User Pin</button>
        </div>
      </div>
    )
  }
}

export default AdminPinDataSidebar