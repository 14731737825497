import React, {useState} from 'react'
import { motion } from "framer-motion"

import MenuData from '../data/MenuData'
import '../sass/components/_MenuBar.scss'
import MenuBarSub from './menu-bar/MenuBarSub'
import MenuBarCollapse from './menu-bar/MenuBarCollapse'
import Icon from '../components/Icons'

const variants = {
  collapsed: {
    opacity: 0,
    width:0
  },
  notCollapsed: {
    opacity: 1,
    width:'auto',
    transition: { 
      staggerChildren: 0.05, 
      staggerDirection: 1,
      type: "ease-in",
      stiffness: 400,
      damping: 40
    }
  }
}

const MenuBar = ({
  currLayer,
  openLayers,
  selectDataLayer,
  toggleEditMode,
  isEditMode,
  toggleInfo,
  isMobile,
  isAdmin,
  isAdminMode,
  toggleAdminMode,
  openVideo,
  menuClicked
}) => {

  //const [isOpen, toggleOpen] = useCycle(false, true)
  const [isOpen, toggleOpen] = useState(false)
  const [menuType, setType] = useState(null)
  const [isCollapsed, collapseMenu] = useState(false)

  //const [currlayer,setLayer] = useState(1)

  const openMenu = (type) => {
    menuClicked()
    if(type !== menuType && isOpen){
      //close and reopen
      toggleOpen(false)
      setTimeout(() => {
        setType(type)
        toggleOpen(true)
      },500)
    } else {
      if(isOpen){
        toggleOpen(false)
        setType(null)
      } else {
        toggleOpen(true)
        setType(type)
      }
      
    }
  }

  const toggleCollapseMenu = () => {
    //if menu open
    if(isOpen){
      toggleOpen(false)
      setType(null)
    }
    collapseMenu(!isCollapsed)
  }

  const selectLayer = (layer) =>{
    //setLayer(layer.id)
    selectDataLayer(layer)
  }

  const getLayersMenu = () => {
    return [
      ...MenuData.layersMenu,
      {
        id:4,
        name:'Your stories',
        url:'',
        colour:'#9b59b6'
      },
      {
        id:3,
        name:'Favourites',
        url:'',
        colour:'#f1c40f'
      },
      {
        id:5,
        name:'Street names'
      }
    ].map(layer => {
      layer.selected = openLayers.find(active => active.id === layer.id)
      return layer
    })
  }

  return (
    <motion.div
      initial={false}
      animate={isOpen ? "open" : "closed"}
      >
      <motion.div 
        animate={isCollapsed ? 'collapsed' : 'notCollapsed'}
        className="main-menu-bar">
        <motion.div 
          className="menu-bar-left"
          variants={variants}>
          <div className={`menu menu-bar-item ${menuType === 'main' && 'active'}`}>
            <Icon name="menu" action={() => openMenu('main')}/>
          </div>
          <div className={`data-layer menu-bar-item ${menuType === 'main' && 'layers'}`}>
            <Icon name="layers-outline" action={() => openMenu('layers')}/>
          </div>
          <div className="map-layer menu-bar-item">
            <Icon name="film-outline" action={()=>openMenu('videos')}/>
          </div>
          <div className="map-layer menu-bar-item">
            <Icon name="information-outline" action={()=>toggleInfo(true)}/>
          </div>
          <div className={`active-inticator ${menuType}`}></div>
        </motion.div>
        <div className="menu-bar-right">
          <MenuBarCollapse toggleCollapseMenu={()=>toggleCollapseMenu()}/>
        </div>
      </motion.div>
      <MenuBarSub
        isOpen={isOpen} 
        menuType={menuType}
        layersMenu={getLayersMenu()}
        toggleEditMode={()=>toggleEditMode()}
        isEditMode={isEditMode}
        isMobile={isMobile}
        isAdmin={isAdmin}
        isAdminMode={isAdminMode}
        toggleAdminMode={()=>toggleAdminMode()}
        openVideo={(video)=>openVideo(video)}
        selectLayer={(layer) => selectLayer(layer)}/>
    </motion.div>
  )
}

export default MenuBar