import React, { Component, Fragment } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { getLocalStorage, setLocalStorage } from "../../helpers/LocalStorage";
import Icon from "../Icons";
import Loader from "../loading-screen/Loader";
import "../../sass/components/side-bar/_PinDataSidebar.scss";
const userImageHostUrl = `https://gallus-glasgow-new.s3.eu-north-1.amazonaws.com/images`;

const variants = {
  closed: {
    opacity: 0,
    transform: "translateY(100%)",
    transition: {
      duration: 0.2,
    },
  },
  open: {
    opacity: 1,
    transform: "translateY(0%)",
    transition: {
      duration: 0.2,
    },
  },
};

class PinDataSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favourites: null,
      isFavourite: false,
      imageLoading: true,
      shared: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        favourites: this.props.favourites,
      },
      () => {
        this.checkIsFavourite();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favourites !== this.props.favourites) {
      this.checkIsFavourite();
    }
    if (prevProps.data !== this.props.data) {
      this.checkIsFavourite();
      this.setState({
        imageLoading: true,
      });
    }
  }

  setFavourite() {}

  favouritePin(data) {
    let favourites;
    if (this.state.favourites) {
      favourites = [...this.state.favourites];
      favourites.push(data);
    } else {
      favourites = [data];
    }
    setLocalStorage("favourites", favourites);
    this.props.pinFavourited();
    this.setState({
      favourites: favourites,
    });
  }

  removeFavourite(data) {
    const favourites = this.state.favourites.filter(
      (fav) => fav.name !== data.name
    );
    setLocalStorage("favourites", favourites);
    this.setState(
      {
        favourites: favourites,
        isFavourite: false,
      },
      () => {
        this.props.pinFavourited();
      }
    );
  }

  sharePin(data) {
    this.setState(
      {
        shared: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            shared: false,
          });
        }, 1000);
      }
    );
    this.props.sharePin(data);
  }

  imageLoaded() {
    this.setState({
      imageLoading: false,
    });
  }

  checkIsFavourite() {
    if (this.state.favourites) {
      const pin = this.state.favourites.find(
        (favourite) => favourite.name === this.props.data.name
      );
      if (pin) {
        this.setState({
          isFavourite: true,
        });
      } else {
        this.setState({
          isFavourite: false,
        });
      }
    }
  }

  render() {
    const { data, isMouseMove } = this.props;

    return (
      <div className={`pin-data-wrapper ${isMouseMove && "mouse-move"}`}>
        {data.image && (
          <div className="pin-data-image">
            {this.state.imageLoading && (
              <div className="image-loader">
                <Loader />
              </div>
            )}
            <img
              onLoad={() => this.imageLoaded()}
              src={
                data.isUser
                  ? `${userImageHostUrl}/${data.image}?tr=w-300`
                  : `/images/thumbs/${data.image}.jpeg`
              }
            />
          </div>
        )}
        <div className="pin-data-header">
          {data.image && (
            <div className="pin-data-image-credit">
              image credit: {data.image_credit}
            </div>
          )}
          <div className="pin-data-name">{data.name}</div>
          {data.address && (
            <div className="pin-data-address">{data.address}</div>
          )}
          <div className="pin-data-date">{data.date}</div>
          {data.architect && (
            <div className="pin-data-architect">
              Architect: {data.architect}
            </div>
          )}
        </div>
        <div className="pin-data-actions">
          {data.hasGallery && (
            <div className="pin-data-action">
              <Icon name="images" />
              <span>gallery</span>
            </div>
          )}
          {
            <div className="pin-data-action">
              <Icon name="share-outline" action={() => this.sharePin(data)} />
              <span>share</span>
              <AnimatePresence>
                {this.state.shared && (
                  <motion.div
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={variants}
                    className="shared"
                  >
                    Copied to clipboard!
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          }
          <div className="pin-data-action">
            {this.state.isFavourite ? (
              <Fragment>
                <Icon name="star" action={() => this.removeFavourite(data)} />
                <span>favourite</span>
              </Fragment>
            ) : (
              <Fragment>
                <Icon
                  name="star-outline"
                  action={() => this.favouritePin(data)}
                />
                <span>favourite</span>
              </Fragment>
            )}
          </div>
        </div>
        <div
          className="pin-data-desc"
          dangerouslySetInnerHTML={{ __html: data.desc }}
        />
      </div>
    );
  }
}

export default PinDataSidebar;
