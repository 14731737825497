import '../sass/components/_MenuOnboarding.scss'

const MenuOnboarding = (props) => {
  return (
    <div className="menu-onboarding-wrapper">
      click here to view map pins
    </div>
  )
}

export default MenuOnboarding