import React from "react";
import "../sass/components/_SidebarRectangle.scss";

const SidebarRectangle = ({ isOpen, isSpecial }) => (
  <div
    className={`sidebar-rectangle ${isOpen && isSpecial ? "visible" : ""} ${
      window.innerWidth <= 768 ? "mobile" : ""
    }`}
  >
    <a
      href="https://ourcitychambers.glasgowheritage.org.uk/"
      target="_blank"
      rel="noopener noreferrer"
      className="sidebar-link"
    >
      Explore Our City Chambers
      <br />
      Start your virtual tour here
    </a>
  </div>
);
export default SidebarRectangle;
