import React, {Component} from 'react'

import InitData from '../data/InitData'
import Icon from './Icons'
import EditPin from './EditPin'

import '../sass/components/_EditLayer.scss'

class EditLayer extends Component {

  constructor(props){
    super(props)
    this.state = {
      editPins:[],
      editPin:{
        x:0,
        y:0
      },
      overlay:true
    }
    this.map = {
      mapX:0,
      mapY:0,
      imgWidth:InitData.imgWidth,
      imgHeight:InitData.imgHeight,
      zoomLevel:InitData.zoomLevel,
      scaleFactor:InitData.scaleFactor
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.editX !== this.props.editX){
      this.setState({
        editPin:{
          x:this.props.editX,
          y:this.props.editY
        }
      })
    }
  }

  getPinX(x){
    const {
      zoomLevel,
      mapX
    } = this.props
    const {
      imgWidth,
      scaleFactor
    } = this.map
    const scaledMapX = (x * (scaleFactor**zoomLevel)) + mapX - 16
    return scaledMapX
  }

  getPinY(y){
    const {
      zoomLevel,
      mapY
    } = this.props
    const {
      scaleFactor
    } = this.map
    const scaledMapY = (y * (scaleFactor**zoomLevel)) + mapY - 55
    return scaledMapY
  }

  getPins(){
    const {
      isMouseMove
    } = this.props

    return this.state.editPins.map((data,index) => {
      return <EditPin
        mouseMove={isMouseMove}
        x={this.getPinX(data.x)}
        y={this.getPinY(data.y)}
        trueX={data.x}
        trueY={data.y}
        key={index}
        expandPin={(data) => this.props.expandPin(data)}
        data={data}
        colour="#93ff5f"/>
    })
  }

  getPin(){
    const {
      isMouseMove
    } = this.props
    const {
      editPin
    } = this.state
    return <EditPin
      mouseMove={isMouseMove}
      expandEditPin={()=>this.props.expandEditPin()}
      x={this.getPinX(editPin.x)}
      y={this.getPinY(editPin.y)}
      colour="#93ff5f"/>
  }

  getOverlayText(){
    if(this.props.isMobile){
      return 'Long press to drop a pin on the map and add your information. This will be sent to our team where we will add it to the map permanently.'
    } else {
      return 'Double click to drop a pin on the map and add your information. This will be sent to our team where we will add it to the map permanently.'
    }
  }

  render(){
    const {
      pinSubmitted,
      continueEditing,
      cancelEditing,
      isMobile
    } = this.props

    return(
      <div className="edit-layer-container">
        {
          pinSubmitted &&
          <div className="edit-overlay">
            <div className="edit-overlay-header">
            </div>
            <div className="edit-overlay-content edit-overlay-continue">
              Continue adding pins?
              <div className="button-wrapper">
                <button className="yes" onClick={()=>continueEditing()}>Continue</button>
                <button className="no" onClick={()=>cancelEditing()}>Finish</button>
              </div>
            </div>
          </div>
        }
        {
          this.state.overlay &&
          <div className={`edit-overlay ${isMobile && 'mobile'}`}>
            <div className="edit-overlay-header">
              <Icon name="close" action={()=>this.setState({
                overlay:false
              })}/>
            </div>
            <div className="edit-overlay-content">
              {this.getOverlayText()}
            </div>
          </div>
        }
        {this.getPin()}
      </div>
    )
  }
}

export default EditLayer