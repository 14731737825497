import React, { Component } from "react";
import { isMobile, isIOS } from "react-device-detect";
import queryString from "qs";
import copy from "copy-to-clipboard";

import "./App.scss";
import { getLocalStorage } from "./helpers/LocalStorage";

import InitData from "./data/InitData";
import DataLayerData from "./data/DataLayerData";

import MenuBar from "./components/MenuBar";
import Sidebar from "./components/Sidebar";
import SidebarRectangle from "./components/SidebarRectangle";
import LoadingScreen from "./components/LoadingScreen";
import IntroLayer from "./components/IntroLayer";
import InfoOverlay from "./components/InfoOverlay";
import MapLayerImg from "./components/MapLayerImg";
import DataLayer from "./components/DataLayer";
import EditLayer from "./components/EditLayer";
import AdminLayer from "./components/AdminLayer";
import VideoWrapper from "./components/VideoWrapper";
import MenuOnboarding from "./components/MenuOnboarding";

import ReactGA from "react-ga";
ReactGA.initialize("UA-207303827-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currLayer: [0],
      openLayers: [],
      dataLayers: [],
      isSidebarOpen: false,
      sidebarData: null,
      isVideo: false,
      isMouseMove: false,
      isTouchMove: false,
      zoomLevel: InitData.zoomLevel,
      mapX: 0,
      mapY: 0,
      isCanvasImgLoading: true,
      isVideoLoading: true,
      appStart: false,
      showInfo: true,
      isEditMode: false,
      editX: 0,
      editY: 0,
      isMuted: false,
      vpHeight: window.innerHeight,
      favourites: null,
      query: null,
      isAdmin: false,
      isAdminMode: false,
      adminUpdate: 1,
      isOverlayVideo: false,
      videoID: null,
      userPins: null,
      onboarded: false,
      pinSubmitted: false,
      streetNames: false,
      queryPin: null,
      isSpecial: false,
    };
  }

  componentDidMount() {
    const query = queryString.parse(window.location.search);
    if (!isMobile) {
      this.setState({
        isVideo: true,
      });
    }
    if (isMobile) {
      this.setState({
        isVideoLoading: false,
      });
    }
    //favourites
    this.setState({
      favourites: getLocalStorage("favourites"),
    });

    //user pins

    fetch(`${InitData.api}/pins`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("First then:", res.status);
        return res.json();
      })
      .then((res) => {
        this.setState(
          {
            userPins: res.map((pin) => {
              pin.isUser = true;
              return pin;
            }),
          },
          () => {
            if (Object.keys(query).length !== 0) {
              this.setState(
                {
                  query: query,
                },
                () => {
                  if (query["?admin"]) {
                    this.checkAdmin();
                  }
                  if (query["?layer_id"]) {
                    this.queryOpenPin();
                    window.history.pushState({}, document.title, "/");
                  }
                }
              );
            }
          }
        );
      })
      .catch((err) => console.log(err));
  }

  queryOpenPin() {
    const { query, userPins } = this.state;
    //user pins
    let dataLayers = DataLayerData;

    dataLayers[4] = {
      id: 4,
      name: "Your stories",
      url: "",
      colour: "#9b59b6",
      data: userPins,
    };
    //console.log(dataLayers)
    const pin = dataLayers[query["?layer_id"]].data.find(
      (pin) => pin.name === decodeURIComponent(query["name"])
    );
    const layer = {
      id: parseInt(query["?layer_id"]),
      selected: true,
    };

    this.setState({
      appStart: true,
      isVideoLoading: false,
      isVideo: false,
      showInfo: false,
    });
    setTimeout(() => {
      this.setState({
        queryPin: pin,
      });
      this.expandPin({
        ...pin,
        selected: true,
      });
      this.toggleDataLayer(layer);
    }, 1500);
  }

  checkAdmin() {
    fetch(`${InitData.api}/admin`, {
      method: "POST",
      body: JSON.stringify({
        admin: this.state.query["?admin"],
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isAdmin: true,
            isVideo: false,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  selectDataLayer(layer) {
    this.setState({
      currLayer: layer.id,
      isSidebarOpen: false,
    });
  }

  toggleDataLayer(layer) {
    if (layer.id === 5) {
      this.setState({
        streetNames: !this.state.streetNames,
      });
    }
    const { openLayers } = this.state;
    //if layer active, deactivate
    const activeLayers = openLayers.find((active) => active.id === layer.id)
      ? openLayers.filter((active) => active.id !== layer.id)
      : openLayers.concat(layer);
    this.setState({
      openLayers: activeLayers,
    });
  }

  expandPin(data) {
    const { isSidebarOpen, sidebarData } = this.state;

    if (isSidebarOpen && data !== sidebarData.data) {
      this.setState({
        isSidebarOpen: false,
      });
      let timeout = setTimeout(() => {
        this.setState({
          isSidebarOpen: true,
          sidebarData: {
            type: "pin-data",
            data: data,
          },
          isSpecial: data.isSpecial || false,
        });
        clearTimeout(timeout);
        timeout = null;
      }, 200);
    } else {
      this.setState({
        isSidebarOpen: true,
        sidebarData: {
          type: "pin-data",
          data: data,
        },
        isSpecial: data.isSpecial || false,
      });
    }
  }

  expandAdminPin(data) {
    const { isSidebarOpen, sidebarData } = this.state;

    if (isSidebarOpen && data !== sidebarData.data) {
      this.setState({
        isSidebarOpen: false,
      });
      let timeout = setTimeout(() => {
        this.setState({
          isSidebarOpen: true,
          sidebarData: {
            type: "admin-mode",
            data: data,
          },
        });
        clearTimeout(timeout);
        timeout = null;
      }, 200);
    } else {
      this.setState({
        isSidebarOpen: true,
        sidebarData: {
          type: "admin-mode",
          data: data,
        },
      });
    }
  }

  pinFavourited() {
    //favourites
    this.setState({
      favourites: getLocalStorage("favourites"),
    });
  }

  closeSidebar = () => {
    this.setState({
      isSidebarOpen: false,
    });
  };

  pinSubmitted() {
    this.setState({
      pinSubmitted: true,
    });
  }

  setMapCoords = (coords) => {
    this.setState({
      mapX: coords.x,
      mapY: coords.y,
    });
  };

  appClick = () => {
    //openSidebar(false)
  };

  setEditXY = (x, y) => {
    this.setState({
      sidebarData: {
        type: "edit-mode",
        data: {
          x: x,
          y: y,
        },
      },
      editX: x,
      editY: y,
      pinSubmitted: false,
    });
    var timeout = setTimeout(() => {
      clearTimeout(timeout);
      timeout = null;
      this.setState({
        isSidebarOpen: true,
      });
    }, 1000);
  };

  skipIntro() {
    console.log("SKIP INTRO");
    this.setState({
      appStart: true,
      isVideoLoading: false,
      isVideo: false,
    });
  }

  expandEditPin() {
    this.setState({
      isSidebarOpen: true,
      sidebarData: {
        ...this.state.sidebarData,
        type: "edit-mode",
      },
    });
  }

  updateAdmin() {
    const number = this.state.adminUpdate + 1;
    this.setState({
      adminUpdate: number,
    });
  }

  openVideo(video) {
    this.setState({
      isOverlayVideo: true,
      videoID: video,
    });
  }

  sharePin(data) {
    const url = `${window.location.origin}?layer_id=${
      data.layer_id
    }&name=${encodeURIComponent(data.name)}`;
    copy(url);
  }

  render() {
    const {
      currLayer,
      openLayers,
      isSidebarOpen,
      sidebarData,
      isVideo,
      isMouseMove,
      isTouchMove,
      zoomLevel,
      mapX,
      mapY,
      isCanvasImgLoading,
      isVideoLoading,
      appStart,
      showInfo,
      isEditMode,
      editX,
      editY,
      isMuted,
      vpHeight,
      favourites,
      isAdmin,
      isAdminMode,
      adminUpdate,
      isOverlayVideo,
      videoID,
      userPins,
      onboarded,
      pinSubmitted,
      streetNames,
      queryPin,
    } = this.state;
    return (
      <div
        onClick={() => this.appClick()}
        className={`App ${isMouseMove && "mouse-move"}`}
      >
        {(isCanvasImgLoading || isVideoLoading || !appStart) && (
          <LoadingScreen
            isCanvasImgLoading={isCanvasImgLoading}
            isVideoLoading={isVideoLoading}
            startApp={() =>
              this.setState({
                appStart: true,
              })
            }
            isMobile={isMobile}
            skipIntro={() => this.skipIntro()}
            muteApp={(isMuted) =>
              this.setState({
                isMuted: isMuted,
              })
            }
          />
        )}
        {appStart && (
          <MenuBar
            currLayer={currLayer}
            openLayers={openLayers}
            selectDataLayer={(layer) => this.toggleDataLayer(layer)}
            isEditMode={isEditMode}
            isMobile={isMobile}
            isAdmin={isAdmin}
            isAdminMode={isAdminMode}
            toggleEditMode={() =>
              this.setState({
                isEditMode: !isEditMode,
                isSidebarOpen: false,
              })
            }
            toggleAdminMode={() =>
              this.setState({
                isAdminMode: !isAdminMode,
                isSidebarOpen: false,
              })
            }
            toggleInfo={() =>
              this.setState({
                showInfo: true,
              })
            }
            menuClicked={() =>
              this.setState({
                onboarded: true,
              })
            }
            openVideo={(video) => this.openVideo(video)}
          />
        )}
        {!onboarded && <MenuOnboarding />}
        <SidebarRectangle
          isOpen={isSidebarOpen}
          isSpecial={this.state.isSpecial}
          data={sidebarData}
        />
        <Sidebar
          isMouseMove={isMouseMove}
          data={sidebarData}
          isOpen={isSidebarOpen}
          closeSidebar={() => this.closeSidebar()}
          pinSubmitted={() => this.pinSubmitted()}
          favourites={favourites}
          pinFavourited={() => this.pinFavourited()}
          vpHeight={vpHeight}
          updateAdminLayer={() =>
            this.setState({
              adminUpdate: this.state.adminUpdate + 1,
            })
          }
          sharePin={(data) => this.sharePin(data)}
        />
        {showInfo & appStart ? (
          <InfoOverlay
            isMobile={isMobile}
            closeInfo={() =>
              this.setState({
                showInfo: false,
              })
            }
          />
        ) : null}
        {isVideo && (
          <IntroLayer
            isMuted={isMuted}
            appStart={appStart}
            canPlayThrough={() =>
              this.setState({
                isVideoLoading: false,
              })
            }
            onVideoEnd={() =>
              this.setState({
                isVideo: false,
              })
            }
          />
        )}
        {!isEditMode & appStart & !isTouchMove & !isAdminMode ? (
          <DataLayer
            currLayer={currLayer}
            openLayers={openLayers}
            isMouseMove={isMouseMove}
            isSidebarOpen={isSidebarOpen}
            mapX={mapX}
            mapY={mapY}
            zoomLevel={zoomLevel}
            favourites={favourites}
            expandPin={(data) => this.expandPin(data)}
            userPins={userPins}
            queryPin={queryPin}
          />
        ) : null}
        {isEditMode & appStart & !isAdminMode ? (
          <EditLayer
            editX={editX}
            editY={editY}
            isMouseMove={isMouseMove}
            mapX={mapX}
            mapY={mapY}
            zoomLevel={zoomLevel}
            isMobile={isMobile}
            expandEditPin={() => this.expandEditPin()}
            pinSubmitted={pinSubmitted}
            continueEditing={() =>
              this.setState({
                pinSubmitted: false,
              })
            }
            cancelEditing={() =>
              this.setState({
                isEditMode: false,
                pinSubmitted: false,
              })
            }
          />
        ) : null}
        {isAdminMode & appStart & !isEditMode ? (
          <AdminLayer
            editX={editX}
            editY={editY}
            isMouseMove={isMouseMove}
            mapX={mapX}
            mapY={mapY}
            zoomLevel={zoomLevel}
            adminUpdate={adminUpdate}
            expandAdminPin={(data) => this.expandAdminPin(data)}
          />
        ) : null}
        {isOverlayVideo && (
          <VideoWrapper
            videoID={videoID}
            closeVideo={() =>
              this.setState({
                isOverlayVideo: false,
                videoID: null,
              })
            }
          />
        )}
        <MapLayerImg
          setMapCoords={(coords) => this.setMapCoords(coords)}
          setZoomLevel={(level) =>
            this.setState({
              zoomLevel: level,
            })
          }
          streetNames={streetNames}
          mouseMove={(move) =>
            this.setState({
              isMouseMove: move,
            })
          }
          touchMove={(move) =>
            this.setState({
              isTouchMove: move,
            })
          }
          canvasImgLoading={(isLoading) =>
            this.setState({
              isCanvasImgLoading: isLoading,
            })
          }
          setEditXY={(x, y) => isEditMode && this.setEditXY(x, y)}
          isMobile={isMobile}
          isEditMode={isEditMode}
          queryPin={queryPin}
        />
      </div>
    );
  }
}

export default App;
