import React, {Fragment} from 'react'
import { motion } from "framer-motion"

import Icon from '../Icons'
import Switch from '../Switch'
import '../../sass/components/menu-bar/_MenuBarSubItem.scss'

const variants = {
  open: {
    y: 0,
    opacity: 1
  },
  closed: {
    y: -5,
    opacity: 0
  }
}

const MenuBarSubMain = ({
  toggleEditMode,
  isEditMode,
  isMobile,
  isAdmin,
  toggleAdminMode,
  isAdminMode
}) => {

  const openLink = (link) => {
    window.open(link, "_blank") || window.location.replace(link)
  }
  return (
    <Fragment>
      {
        !isAdmin ?
        <motion.div
          variants={variants}
          className="menu-bar-sub-item"
          onClick={()=>toggleEditMode()}>
            Tell us your story <Switch checked={isEditMode}/>
        </motion.div> :
        null
      }
      {
        isAdmin &&
        <motion.div
          variants={variants}
          className="menu-bar-sub-item"
          onClick={()=>toggleAdminMode()}>
            Verify pins {isAdminMode && <Icon name="checkmark-circle-outline"/>}
        </motion.div>
      }
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openLink('https://www.glasgowheritage.org.uk/gallusglasgow/about/')}
        >
          About Gallus Glasgow <Icon name="open"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openLink('https://www.glasgowheritage.org.uk/gallusglasgow/meetthefamily/')}
        >
          Meet the Family <Icon name="open"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openLink('https://www.glasgowheritage.org.uk/gallusglasgow/blog')}
        >
          Blog <Icon name="open"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openLink('https://www.glasgowheritage.org.uk/gallusglasgow/events/')}
        >
          Events <Icon name="open"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openLink('https://www.glasgowheritage.org.uk/gallusglasgow/prints/')}
        >
          Buy prints <Icon name="open"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openLink(' https://gcht.typeform.com/to/n3wBZikZ')}
        >
          Send feedback <Icon name="open"/>
      </motion.div>
    </Fragment>
  )
}

export default MenuBarSubMain