import React, { Component } from "react";

import InitData from "../data/InitData";
import DataLayerData from "../data/DataLayerData";

import Pin from "./Pin";

class DataLayer extends Component {
  constructor(props) {
    super(props);

    this.viewport = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    this.map = {
      mapX: 0,
      mapY: 0,
      imgWidth: InitData.imgWidth,
      imgHeight: InitData.imgHeight,
      zoomLevel: InitData.zoomLevel,
      scaleFactor: InitData.scaleFactor,
    };
    this.state = {
      colour: null,
      pins: [],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.viewport = {
        height: window.innerHeight,
        width: window.innerWidth,
      };
    });
    //favourites
    let dataLayers = DataLayerData;
    dataLayers.push({
      colour: "#f1c40f",
      data: this.props.favourites,
    });
    //user pins
    dataLayers.push({
      colour: "#9b59b6",
      data: this.props.userPins,
    });
    this.setState(
      {
        dataLayers: dataLayers,
      },
      () => {
        this.setPins();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openLayers !== this.props.openLayers) {
      this.setPins();
    }
    if (prevProps.favourites !== this.props.favourites) {
      let dataLayers = this.state.dataLayers;
      dataLayers[3].data = this.props.favourites;
      this.setState({
        dataLayers: dataLayers,
      });
    }
    if (prevProps.userPins !== this.props.userPins) {
      let dataLayers = this.state.dataLayers;
      dataLayers[4].data = this.props.userPins;
      this.setState({
        dataLayers: dataLayers,
      });
    }
  }

  setPins() {
    const { currLayer, openLayers, queryPin } = this.props;

    const { dataLayers } = this.state;

    let pins = [];
    for (const layer of openLayers) {
      //exclude street names
      if (layer.id !== 5 && dataLayers[layer.id] && dataLayers[layer.id].data) {
        for (let data of dataLayers[layer.id].data) {
          data.colour = data.isSpecial
            ? "#FF45BA"
            : dataLayers[layer.id].colour;
          data.selected = false;
          data.layer_id = layer.id;
          if (queryPin && queryPin.name === data.name) {
            data.selected = true;
          }
          pins.push(data);
        }
      }
    }
    const selectedLayer =
      currLayer === 999 ? 3 : currLayer === 998 ? 4 : currLayer;
    //pins
    this.setState({
      colour: this.state.dataLayers[selectedLayer].colour,
      pins: pins,
    });
  }

  getPinX(x) {
    const { zoomLevel, mapX } = this.props;
    const { imgWidth, scaleFactor } = this.map;
    const scaledMapX = x * scaleFactor ** zoomLevel + mapX - 16;
    return scaledMapX;
  }

  getPinY(y) {
    const { zoomLevel, mapY } = this.props;
    const { scaleFactor } = this.map;
    const scaledMapY = y * scaleFactor ** zoomLevel + mapY - 54;
    return scaledMapY;
  }

  expandPin(data) {
    const pins = this.state.pins.map((pin) => {
      pin.selected = false;
      if (pin.name === data.name) {
        pin.selected = true;
      }
      return pin;
    });
    this.setState({
      pins: pins,
    });
    this.props.expandPin(data);
  }

  getPins() {
    const { isMouseMove, currLayer } = this.props;

    return this.state.pins.map((data, index) => {
      return (
        <Pin
          mouseMove={isMouseMove}
          x={this.getPinX(data.x)}
          y={this.getPinY(data.y)}
          key={index}
          expandPin={(data) => this.expandPin(data)}
          data={data}
          colour={data.colour}
        />
      );
    });
  }

  render() {
    return <div className="data-layer-container">{this.getPins()}</div>;
  }
}

export default DataLayer;
