const MenuData = {
  layersMenu:[
    {
      id:0,
      name:'Surviving Buildings',
      url:'',
      colour:'#187998',
      selected:true
    },
    {
      id:1,
      name:'Lost Buildings',
      url:'',
      colour:'#e5193d'
    },
    {
      id:2,
      name:'Buildings Built 1865-1914',
      url:'',
      colour:'#27ae60'
    },
    /*{
      id:2,
      name:'Data Layer 3',
      url:'',
      colour:'#f2ae2c'
    },
    {
      id:3,
      name:'Data Layer 4',
      url:'',
      colour:'#4f8c5d'
    },
    {
      id:4,
      name:'Data Layer 5',
      url:'',
      colour:'#62b5bd'
    }*/
  ]
}

export default MenuData