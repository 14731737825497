const InitData = {
  imgWidth: 12000,
  imgHeight: 4691,
  zoomLevel: 32,
  scaleFactor: 0.9,
  mapImage: {
    desktop:
      "https://s3.eu-west-1.amazonaws.com/sulman-project.com/map_full_borderless_40_80perc.jpg",
    mobile:
      "https://s3.eu-west-1.amazonaws.com/sulman-project.com/map_full_borderless_40_80perc_50_80perc.jpg",
  },
  newMapImage: {
    desktop:
      "https://s3.eu-west-1.amazonaws.com/sulman-project.com/will_knight_adam_sulman_website_map_full_borderless_40_80perc_230404_1.jpg",
    mobile:
      "https://s3.eu-west-1.amazonaws.com/sulman-project.com/will_knight_adam_sulman_website_map_full_borderless_40_80perc_230404_1.jpg",
  },
  //api: "https://gallus-glasgow.herokuapp.com",
  //api: "http://localhost:3333",
  api: "https://gallus-api-4e5m.onrender.com",
};

export default InitData;
