import React, {Fragment} from 'react'
import { motion } from "framer-motion"

import Icon from '../Icons'
import '../../sass/components/menu-bar/_MenuBarSubItem.scss'

const variants = {
  open: {
    y: 0,
    opacity: 1
  },
  closed: {
    y: -5,
    opacity: 0
  }
}

const MenuBarSubVideos = ({
  openVideo
}) => {

  return (
    <Fragment>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openVideo('Gallus_Glasgow_210913')}
        >
          Step into 'Sulman's City, 1864' <Icon name="play-circle-outline"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openVideo('liz_cut_210913')}
        >
          Elizabeth/Charing Cross<Icon name="play-circle-outline"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openVideo('wynds_cuts_210907')}
        >
          George/High Street Wynds<Icon name="play-circle-outline"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openVideo('green_cuts_210907')}
        >
          Heather/Glasgow Green<Icon name="play-circle-outline"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openVideo('docks_cuts_210907')}
        >
          Thomas/Broomielaw<Icon name="play-circle-outline"/>
      </motion.div>
      <motion.div
        variants={variants}
        className="menu-bar-sub-item"
        onClick={()=>openVideo('temp_cut_210907')}
        >
          Edward/Merchant City<Icon name="play-circle-outline"/>
      </motion.div>
    </Fragment>
  )
}

export default MenuBarSubVideos