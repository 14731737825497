import React from 'react'

import Icon from './Icons'
import GCHTLogo from '../assets/img/gcht_logo_white.png'
import HFFLogo from '../assets/img/hugh_fraser_foundation.png'
import CNFSLogo from '../assets/img/CBFS_Solid_Monowhite.png'
import NorbulkLogo from '../assets/img/norbulk_logo_white.png'
import '../sass/components/_InfoOverlay.scss'

const InfoOverlay = ({closeInfo,isMobile}) => {

  const openLink = (link) => {
    window.open(link, "_blank") || window.location.replace(link)
  }

  return(
    <div className="info-overlay-container" onClick={()=>closeInfo()}>
      <div className="close-wrapper" onClick={()=>closeInfo()}>
        <Icon name="close"/>
      </div>
      <div className="info-overlay-title">
        Welcome to Gallus Glasgow
      </div>
      <div className="info-overlay-desc">
        <span>Step into Thomas Sulman’s intricate 1864 map of Glasgow and explore the next 50 years of the city’s development, as it became 'the Second City of the Empire'.</span>
        <span>Legend has it that Thomas Sulman took to a hot air balloon to draw the map for the Illustrated London News, which gives it a unique and highly detailed perspective. 
          The map captures a city on the cusp of greatness: the Victorians have found success in manufacturing and trading, they’ve conquered much of the world.
          They celebrate these achievements by constructing extravagant buildings in which to work, live and meet. It’s an exciting time, full of opportunity and optimism.
        </span>
        <span>
          Follow a family of five as they navigate a day in the city - when the spectacularly rich were living side-by-side with some of the poorest in Britain.
          Get involved and add your voice to our Gallus Glasgow story.
        </span>
      </div>
      <div className="info-overlay-legend">
        <div className="legend-header">
          Using the Map
        </div>
        <div className="info-overlay-legend-item">
          <div className="legend-item-top">
            <Icon name="menu"></Icon><span className="legend-item-title">Menu</span>
          </div>
          <div className="legend-item-bottom">
            Tell us your story, buy prints, and explore other content.
          </div>
        </div>
        <div className="info-overlay-legend-item">
          <div className="legend-item-top">
            <Icon name="map-outline"></Icon><span className="legend-item-title">The Knight Map</span>
          </div>
          <div className="legend-item-bottom">
          Compare and contrast Sulman's Victorian Glasgow with today’s city via The Knight Map, a contemporary artwork commissioned by Glasgow City Heritage Trust and created by artist Will Knight. Add pins and continue to share your stories with us.
          </div>
        </div>
        <div className="info-overlay-legend-item">
          <div className="legend-item-top">
            <Icon name="film-outline"></Icon><span className="legend-item-title">Videos</span>
          </div>
          <div className="legend-item-bottom">
            Click this icon and Select 'Step into Sulman's City, 1864' to watch the full animation or select Character name or city area to watch individual chapters.
          </div>
        </div>
        <div className="info-overlay-legend-item">
          <div className="legend-item-top">
            <svg 
              width="18px"
              height="33px" 
              viewBox="0 0 358 552" version="1.1">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(3.000000, 3.000000)" fillRule="nonzero">
                  <g id="Map_marker" fill={'#ffffff'} stroke={'#000000'} strokeLinejoin="round" strokeWidth="12">
                    <path d="M175.9,544.7 C175.9,544.8 176.1,545 176.1,545 C176.1,545 351.3,276 351.3,187.6 C351.3,57.5 262.5,0.9 175.9,0.7 C89.3,0.9 0.5,57.5 0.5,187.6 C0.5,276 175.8,545 175.8,545 C175.8,545 175.9,544.7 175.9,544.7 Z M115.2,180.2 C115.2,146.6 142.4,119.4 176,119.4 C209.6,119.4 236.8,146.6 236.8,180.2 C236.8,213.8 209.5,241 175.9,241 C142.4,241 115.2,213.8 115.2,180.2 Z" id="Shape"></path>
                  </g>
                  <ellipse id="Oval" fill={'#000000'} cx="176.5" cy="180" rx="62.5" ry="62"></ellipse>
                </g>
              </g>
            </svg>
            <span className="legend-item-title">Your Stories</span>
          </div>
          <div className="legend-item-bottom">
            Get involved and add your voice to our Gallus Glasgow story! Spotted something interesting? Got a good story to tell about Victorian Glasgow? Tell us what you can see in the map by clicking on 'Tell us your story’ in the main menu. {isMobile ? 'Make a long press ' : 'Double click'} to drop a pin on the map and add your information. This will be sent to our team where we will add it to the map permanently. View all the community contributions in the Your Stories data layer. 
          </div>
        </div>
        <div className="info-overlay-legend-item">
          <div className="legend-item-top">
            <Icon name="layers-outline"></Icon><span className="legend-item-title">Data Layers</span>
          </div>
          <div className="legend-item-bottom">
            Change what you can view on the map.
          </div>
        </div>
        <div className="info-overlay-legend-item">
          <div className="legend-item-top">
            <Icon name="information-outline"></Icon><span className="legend-item-title">Information</span>
          </div>
          <div className="legend-item-bottom">
            Click this icon to reopen this overlay.
          </div>
        </div>
        <div className="info-overlay-legend-item">
          <div className="legend-item-top">
            <Icon name="share-outline"></Icon><span className="legend-item-title">Social Media</span>
          </div>
          <div className="legend-item-bottom">
            <div className="social-links">
              <Icon name="logo-twitter" action={()=>openLink('https://twitter.com/GlasgowHeritage')}/>
              <Icon name="logo-facebook" action={()=>openLink('https://www.facebook.com/GlasgowHeritage')}/>
              <Icon name="logo-instagram" action={()=>openLink('https://www.instagram.com/GlasgowHeritage/')}/>
            </div>
          </div>
        </div>
      </div>
      <div className="info-overlay-partners">
        <div className="partners-header">
          Our Partners
        </div>
        <div className="partners-logos">
          <div className="image-wrapper">
            <img src={GCHTLogo}/>
          </div>
          <div className="image-wrapper">
            <img src={CNFSLogo}/>
          </div>
          <div className="image-wrapper">
            <img src={NorbulkLogo}/>
          </div>
          <div className="image-wrapper">
            <img src={HFFLogo}/>
          </div>
        </div>
      </div>
      <div className="app-version">
        v{process.env.REACT_APP_VERSION}
      </div>
    </div>
  )
}

export default InfoOverlay