export function getLocalStorage(key){
  const data = localStorage.getItem(key) || null
  return JSON.parse(data)
}

export function setLocalStorage(key,data){
  return localStorage.setItem(key,JSON.stringify(data))
}

export function deleteLocalStorage(key){
  return localStorage.removeItem(key)
}