import React from 'react'
import Vimeo from '@u-wave/react-vimeo'
import '../sass/components/_VideoWrapper.scss'

import Icon from './Icons'
import Main from './video-wrapper/Gallus_Glasgow_en-IE.vtt'
import Docks from './video-wrapper/Docks_en-GB.vtt'
import Elizabeth from './video-wrapper/Elizabeth_en-US.vtt'
import Green from './video-wrapper/Green_en-GB.vtt'
import Temp from './video-wrapper/Temp_en-GB.vtt'
import Wynds from './video-wrapper/Wynds_en-GB.vtt'

const VideoWrapper = (props) => {

  const subtitles = {
    'Gallus_Glasgow_210913':Main,
    'liz_cut_210913':Elizabeth,
    'wynds_cuts_210907':Wynds,
    'green_cuts_210907':Green,
    'docks_cuts_210907':Docks,
    'temp_cut_210907':Temp
  }
    

  const {
    videoID,
    closeVideo
  } = props

  console.log(subtitles)
  return(
    <div className="vimeo-video-wrapper">
      <Icon name="close-outline" action={()=>closeVideo()}/>
      <video controls controlsList="nodownload">
        <source src={`https://s3.eu-west-1.amazonaws.com/sulman-project.com/${videoID}.mp4`} type="video/mp4"/>
        <source src={`https://s3.eu-west-1.amazonaws.com/sulman-project.com/${videoID}.webm`} type="video/webm"/>
        <track label="English" kind="subtitles" srcLang="en" src={subtitles[videoID]} default/>
      </video>
    </div>
  )
}

export default VideoWrapper