import '../sass/components/_Switch.scss'

const Switch = (props) => {

  return(
    <label className="switch">
      <input type="checkbox" checked={props.checked || false} onChange={(e)=>console.log(e)}/>
      <span className="slider round"></span>
    </label>
  )
}

export default Switch