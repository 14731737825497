import React, {Component} from 'react'

import InitData from '../data/InitData'
import { LongPressHOC } from './hocs/LongPressHOC'

import '../sass/components/_MapLayerImg.scss'
import OpacityImg from '../assets/img/transition_button_2.svg'
import StreetNames from './map-layer/street_names.svg'

import Icon from './Icons'

class MapLayerImg extends Component{

  constructor(props){
    super(props)

    this.viewport = {
      height:window.innerHeight,
      width:window.innerWidth,
    }

    this.file = null
    this.newImageFile = null

    this.longPressTimer = null

    this.state = {
      mouseDown:false,
      touchStart:true,
      mapX:0,
      mapY:0,
      touchX:0,
      touchY:0,
      imgWidth:InitData.imgWidth,
      imgHeight:InitData.imgHeight,
      zoomLevel:InitData.zoomLevel,
      scaleFactor:InitData.scaleFactor,
      vpHeight:window.innerHeight,
      vpWidth:window.innerWidth,
      loaded:false,
      maxZoom:5,
      hypo:0,
      imageOpacity:1
    }
  }

  componentDidMount(){
    
    window.addEventListener('resize', ()=>{
      this.setState({
        vpHeight:window.innerHeight,
        vpWidth:window.innerWidth
      })
      //@TODO recalculate mapX/mapY and zoom level
    })
    const {
      isMobile
    } = this.props
    //max zoom 
    if(isMobile){
      this.setState({
        maxZoom:9
      })
    }
    //get map image
    this.loadImages()
    //bind scroll from non passive listener
    this.imageRef.addEventListener('wheel',e => this.scrollMap(e),{passive:false})
  }

  loadImages(){
    const {
      isMobile
    } = this.props
    const worker = new Worker('./web-workers/fetchImage.worker.js')
    worker.postMessage(InitData.mapImage[isMobile ? 'mobile' : 'desktop'])
    worker.onmessage = (e) => {
      const blob = e.data.image
      this.file = URL.createObjectURL(blob)
      worker.terminate()
      this.loadNewImage()
    }
  }

  loadNewImage(){
    const {
      isMobile
    } = this.props
    const worker = new Worker('./web-workers/fetchImage.worker.js')
    worker.postMessage(InitData.newMapImage[isMobile ? 'mobile' : 'desktop'])
    worker.onmessage = (e) => {
      const blob = e.data.image
      this.newImageFile = URL.createObjectURL(blob)
      worker.terminate()
      this.onLoad()
    }
  }

  onLoad(){
    console.log('ON LOAD')
    this.props.canvasImgLoading(false)
    const {
      imgWidth,
      imgHeight,
      zoomLevel,
      scaleFactor,
      vpWidth,
      vpHeight
    } = this.state
    //calc initial image dimensions
    let initImgWidth = imgWidth * (scaleFactor ** zoomLevel)
    let initImgHeight = imgHeight * (scaleFactor ** zoomLevel)
    //check image dimensions are larger than viewport
    //let zm = this.state.zoomLevel
    for(let i = zoomLevel;i > -1;i--){
      if(imgHeight * (scaleFactor ** i) < vpHeight){
        initImgWidth = imgWidth * (scaleFactor ** (i-1))
        initImgHeight = imgHeight * (scaleFactor ** (i-1))
        this.setState({
          zoomLevel:i
        })
        this.props.setZoomLevel(i)
      }
    }
    //set init coords
    const mapX = ((initImgWidth/2) - (vpWidth/2)) *-1
    const mapY = (initImgHeight - vpHeight)*-1

    this.props.setMapCoords({x:mapX,y:mapY})
    this.setState({
      imgHeight:Math.floor(initImgHeight),
      imgWidth:Math.floor(initImgWidth),
      mapX:mapX,
      mapY:mapY,
      loaded:true
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps.queryPin !== this.props.queryPin){
      this.centerMapOnPin()
    }
  }

  centerMapOnPin(){
    
  }

  onMouseDown(evt){
    this.setState({
      mouseDown:true
    })
  }

  onTouchStart(evt){
    this.setState({
      touchStart:true,
      longPressStart:new Date().getTime(),
      touchX:evt.nativeEvent.touches[0].clientX,
      touchY:evt.nativeEvent.touches[0].clientY
    },()=>{
      if(this.props.isEditMode && !this.longPressTimer){
        this.startLongPressTimer()
      }
    })
  }

  startLongPressTimer(){
    this.longPressTimer = setInterval(()=>{
      const now = new Date().getTime()
      if(now - 400 > this.state.longPressStart){
        const {
          mapX,
          mapY,
          scaleFactor,
          zoomLevel,
          touchX,
          touchY
        } = this.state
        this.props.setEditXY(
          (touchX + (mapX *-1))/scaleFactor**zoomLevel,
          (touchY + (mapY *-1))/scaleFactor**zoomLevel
        )
        this.endLongPress()
      }
    },100)
  }

  endLongPress(){
    clearInterval(this.longPressTimer)
    this.longPressTimer = null
  }

  onMouseUp(evt){
    this.props.mouseMove(false)
    this.setState({
      mouseDown:false
    })
  }

  onTouchEnd(){
    this.props.touchMove(false)
    this.setState({
      touchStart:false
    })
    if(this.props.isEditMode){
      this.endLongPress()
    }
  }

  onMouseOut(evt){
    this.props.mouseMove(false)
    this.setState({
      mouseDown:false
    })
  }
  
  onTouchCancel(evt){
    this.props.touchMove(false)
    this.setState({
      touchStart:false
    })
    if(this.props.isEditMode){
      this.endLongPress()
    }
  }

  onMouseMove(evt){
    evt.preventDefault()
    
    if(this.state.mouseDown){
      this.props.mouseMove(true)
      const {
        imgWidth,
        imgHeight,
        mapX,
        mapY,
        vpWidth,
        vpHeight
      } = this.state

      const calcX = evt.nativeEvent.movementX + mapX
      const calcY = evt.nativeEvent.movementY + mapY
      const x = calcX > 0 ? 0 : ( (calcX - vpWidth)*-1 > imgWidth ? (imgWidth - vpWidth)*-1 : calcX)
      const y = calcY > 0 ? 0 : ( (calcY - vpHeight)*-1 > imgHeight ? (imgHeight - vpHeight)*-1 : calcY)
      this.setState({
        mapX:x,
        mapY:y
      })
      //send for data layer
      this.props.setMapCoords({x:x,y:y})
    }
  }
  
  onTouchMove(evt){
    //console.log(evt.nativeEvent)
    if(this.props.isEditMode){
      this.endLongPress()
    }
    if (evt.nativeEvent.touches.length === 2) {
      //touch pinch
      let hypo = Math.hypot(
        (evt.nativeEvent.touches[0].pageX - evt.nativeEvent.touches[1].pageX),
        (evt.nativeEvent.touches[0].pageY - evt.nativeEvent.touches[1].pageY)
      )
      if(this.state.hypo && this.state.hypo > hypo){
        this.zoomOut()
      }
      if(this.state.hypo && this.state.hypo < hypo){
        this.zoomIn()
      }
      this.setState({
        hypo:hypo
      })
    }

    if(this.state.touchStart){
      this.props.touchMove(true)
      const {
        imgWidth,
        imgHeight,
        mapX,
        mapY,
        touchX,
        touchY,
        vpWidth,
        vpHeight
      } = this.state
      
      const calcX = (evt.nativeEvent.touches[0].clientX - touchX) + mapX
      const calcY = (evt.nativeEvent.touches[0].clientY - touchY) + mapY

      const x = calcX > 0 ? 0 : ( (calcX - vpWidth)*-1 > imgWidth ? (imgWidth - vpWidth)*-1 : calcX)
      const y = calcY > 0 ? 0 : ( (calcY - vpHeight)*-1 > imgHeight ? (imgHeight - vpHeight)*-1 : calcY)

      this.setState({
        mapX:x,
        mapY:y,
        touchX:evt.nativeEvent.touches[0].clientX,
        touchY:evt.nativeEvent.touches[0].clientY
      })
      //send for data layer
      this.props.setMapCoords({x:x,y:y})
    }
  }

  setMapXYZoomIn(){
    const {
      mapY,
      mapX,
      scaleFactor,
      vpWidth,
      vpHeight
    } = this.state
    //do i need to take into account screen ratio here? no. Just remember which way x is 
    const x = (((vpWidth - (vpWidth/scaleFactor))/2)/scaleFactor)+(mapX/scaleFactor)
    const y = (((vpHeight - (vpHeight/scaleFactor))/2)/scaleFactor)+(mapY/scaleFactor)
    this.setState({
      mapX:x,
      mapY:y
    })
    this.props.setMapCoords({x:x,y:y})
  }

  zoomIn(){
    const {
      zoomLevel,
      imgHeight,
      imgWidth,
      scaleFactor,
      maxZoom
    } = this.state
    if(zoomLevel > maxZoom){
      this.setState({
        zoomLevel:zoomLevel-1,
        imgHeight:Math.floor(imgHeight/scaleFactor),
        imgWidth:Math.floor(imgWidth/scaleFactor),
      },()=>{
        const {
          zoomLevel
        } = this.state
        //send to App.js
        this.props.setZoomLevel(zoomLevel)
        this.setMapXYZoomIn()
      })
    }
  }

  setMapXYZoomOut(){
    const {
      mapY,
      mapX,
      imgHeight,
      imgWidth,
      scaleFactor,
      vpWidth,
      vpHeight
    } = this.state

    const x = ((imgWidth - vpWidth) < (mapX * -1) ? (imgWidth - vpWidth)*-1 : (((vpWidth - (vpWidth*scaleFactor))/2)*scaleFactor)+(mapX*scaleFactor))
    const y = ((imgHeight - vpHeight) < (mapY * -1) ? (imgHeight - vpHeight)*-1 : (((vpHeight - (vpHeight*scaleFactor))/2)*scaleFactor)+(mapY*scaleFactor))
    this.setState({
      mapX:x,
      mapY:y
    })
    this.props.setMapCoords({x:x,y:y})
  }

  zoomOut(){
    const {
      zoomLevel,
      imgHeight,
      imgWidth,
      scaleFactor,
      vpHeight
    } = this.state
    if(zoomLevel <= InitData.zoomLevel && imgHeight*scaleFactor > vpHeight){
      this.setState({
        zoomLevel:zoomLevel+1,
        imgHeight:Math.floor(imgHeight*scaleFactor),
        imgWidth:Math.floor(imgWidth*scaleFactor)
      },()=>{
        const {
          zoomLevel
        } = this.state
        //send to App.js
        this.props.setZoomLevel(zoomLevel)
        this.setMapXYZoomOut()
      })
    }
  }

  getCoordinates(evt){
    const {
      mapX,
      mapY,
      scaleFactor,
      zoomLevel
    } = this.state
    this.props.setEditXY(
      (evt.nativeEvent.clientX + (mapX *-1))/scaleFactor**zoomLevel,
      (evt.nativeEvent.clientY + (mapY *-1))/scaleFactor**zoomLevel
    )
  }

  scrollMap(e){
    e.preventDefault()
    if(Number.isInteger(e.deltaY)){
      //scrollwheel
      if(e.deltaY > 30){
        this.zoomIn()
      }
      if(e.deltaY < -30){
        this.zoomOut()
      }
    } else {
      //is pinch
      if(e.deltaY > 3.5){
        this.zoomOut()
      }
      if(e.deltaY < -3.5){
        this.zoomIn()
      }
    }
  }

  onPinch(evt){

    console.log(evt)
  }

  decreaseOpacity(){
    this.setState({
      imageOpacity:this.state.imageOpacity > 0 ? this.state.imageOpacity - 0.1 : 0
    })
  }

  increaseOpacity(){
    this.setState({
      imageOpacity:this.state.imageOpacity < 1 ? this.state.imageOpacity + 0.1 : 1
    })
  }

  render(){
    const {
      imgWidth,
      imgHeight,
      mapX,
      mapY,
      loaded,
      imageOpacity
    } = this.state
    return(
      <div className="map-layer-img-container" onClick={(e)=>e.preventDefault}>
        {
          this.props.streetNames && 
          <img 
            className="street-names"
            style={{
              top:mapY,
              left:mapX
            }}
            src={StreetNames}
            height={imgHeight}
            width={imgWidth}/>
        }
        <img 
          style={{
            top:mapY,
            left:mapX,
            touchAction:'none'
          }}
          onContextMenu={event => event.preventDefault()}
          ref={ref => this.newImageRef = ref}
          src={this.newImageFile}
          onDoubleClick={(evt)=>this.getCoordinates(evt)}
          onMouseDown={(evt) => this.onMouseDown(evt)}
          onMouseUp={(evt) => this.onMouseUp(evt)}
          onMouseMove={(evt) => this.onMouseMove(evt)}
          onMouseOut={(evt) => this.onMouseOut(evt)}
          onTouchStart={(evt) => this.onTouchStart(evt)}
          onTouchMove={(evt) => this.onTouchMove(evt)}
          onTouchEnd={(evt) => this.onTouchEnd(evt)}
          onTouchCancel={(evt) => this.onTouchCancel(evt)}
          height={imgHeight}
          width={imgWidth}/>
        <img 
          style={{
            top:mapY,
            left:mapX,
            touchAction:'none',
            opacity:imageOpacity
          }}
          onContextMenu={event => event.preventDefault()}
          ref={ref => this.imageRef = ref}
          src={this.file}
          onDoubleClick={(evt)=>this.getCoordinates(evt)}
          onMouseDown={(evt) => this.onMouseDown(evt)}
          onMouseUp={(evt) => this.onMouseUp(evt)}
          onMouseMove={(evt) => this.onMouseMove(evt)}
          onMouseOut={(evt) => this.onMouseOut(evt)}
          onTouchStart={(evt) => this.onTouchStart(evt)}
          onTouchMove={(evt) => this.onTouchMove(evt)}
          onTouchEnd={(evt) => this.onTouchEnd(evt)}
          onTouchCancel={(evt) => this.onTouchCancel(evt)}
          height={imgHeight}
          width={imgWidth}/>
        {
          loaded && 
          <div className="zoom-controls">
            <div className="controls" onClick={()=>this.zoomIn()}>
              <Icon name="add"/>
            </div>
            <div className="spacer">
              <Icon name="search-outline"/>
            </div>
            <div className="controls" onClick={()=>this.zoomOut()}>
              <Icon name="remove"/>
            </div>
          </div>
        }
        {
          loaded && 
          <div className="opacity-controls">
            <div className="controls" onClick={()=>this.decreaseOpacity()}>
              <Icon name="add"/>
            </div>
            <div className="spacer">
              <img src={OpacityImg}/>
            </div>
            <div className="controls" onClick={()=>this.increaseOpacity()}>
              <Icon name="remove"/>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default MapLayerImg