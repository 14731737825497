import React from 'react'

import Loader from './Loader'

const Loading = ({logo}) => {

  return(
    <div className="loader-wrapper">
      <img src={logo} alt="Main Sulman Map Logo"/>
      <Loader/>
    </div>
  )
}

export default Loading