import React, {useEffect, useState} from 'react'

import Icon from '../Icons'

const Enter = ({
  startApp,
  muteApp,
  logo,
  skipIntro,
  isMobile
}) => {

  const [isMuted, stateMuteVolume] = useState(false)

  const muteVolume = (isMuted) => {
    stateMuteVolume(isMuted)
    muteApp(isMuted)
  }

  return(
    <div className="enter-wrapper">
      <img src={logo}/>
      <div className="enter-controls-top">
        {
          isMuted ? 
          <Icon name="volume-mute-outline" action={()=>muteVolume(!isMuted)}/> :
          <Icon name="volume-high-outline" action={()=>muteVolume(!isMuted)}/>
        }
      </div>
      <div className="enter-controls-bottom">
        <button className="enter-button" onClick={()=>startApp()}>
          Enter Site
        </button>
        {
          !isMobile && 
          <button className="enter-button" onClick={()=>skipIntro()}>
            Skip Intro
          </button>
        }
      </div>
    </div>
  )
}

export default Enter