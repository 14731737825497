import React from 'react'

import '../sass/components/_LoadingScreen.scss'
import LogoMain from '../assets/img/logo_main.png'
import Enter from './loading-screen/Enter'
import Loading from './loading-screen/Loading'

const LoadingScreen = ({
  isCanvasImgLoading,
  isVideoLoading,
  startApp,
  muteApp,
  skipIntro,
  isMobile
}) => {

  return(
    <div className={`loading-screen-container ${!isVideoLoading && 'video-loaded'}`}>
      {
        (isVideoLoading || isCanvasImgLoading) ? 
        <Loading logo={LogoMain}/> : 
        <Enter 
          isMobile={isMobile}
          logo={LogoMain}
          skipIntro={()=>skipIntro()}
          muteApp={(isMuted)=>muteApp(isMuted)}
          startApp={()=>startApp()}/>
      }
    </div>
  )
}

export default LoadingScreen