import React, {Component, Fragment, useState} from 'react'

import InitData from '../../data/InitData'
import Icon from '../Icons'
import Loader from '../loading-screen/Loader'
import '../../sass/components/side-bar/_EditModeSidebar.scss'

class EditModeSidebar extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      imageCredit:null,
      title:null,
      desc:null,
      email:null,
      newsletter:false,
      displayImage:null,
      imageFile:null,
      imageFileUUID:null,
      imageFileUrl:null,
      imageUploaded:false,
      formComplete:false,
      x:null,
      y:null,
    }
    this.allowedFiles = [
      'image/png',
      'image/jpg',
      'image/jpeg'
    ]
    this.upload = React.createRef()
  }

  componentDidMount(){
    const {data} = this.props
    this.setState({
      x:data.x,
      y:data.y
    })
  }
  
  componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      const {data} = this.props
      this.setState({
        x:data.x,
        y:data.y
      })
    }
  }

  updateInput(e,input){
    this.setState({
      [input]:e.target.value
    })
  }

  updateNewsletter(e){
    this.setState({
      newsletter:e.target.checked
    })
  }

  selectFile(e){
    const file = e.target.files[0]
    if(this.allowedFiles.includes(file.type)){
      this.setState({
        displayImage:URL.createObjectURL(file),
        imageFile:file
      },()=>{
        const requestObject = {
          method:'POST' ,
          body:JSON.stringify({
            fileName: file.name ,
            fileType: file.type
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
        fetch(`${InitData.api}/upload-url`,requestObject)
          .then(res => res.json())
          .then(json => {
            this.setState({
              imageFileUrl:json.url,
              imageFileUUID:json.imageFileUUID
            })
          })
          .catch(err => console.log(err))
      })
    }
  }

  submitPin(){
    const {
      imageFileUrl,
      imageFile,
      imageFileUUID
    } = this.state
    //upload image
    if(imageFileUrl && imageFile){
      fetch(imageFileUrl , {
        method:'PUT',
        body :imageFile,
        Key:imageFileUUID
      })
      .then(res => res.json())
      .then((res) => {
        console.log(res)
      })
      .catch(err => console.log(err))
    }
    if(this.checkFormValid()){
      const {
        imageFileUUID,
        imageFile,
        imageCredit,
        title,
        desc,
        email,
        newsletter,
        x,
        y
      } = this.state
      //send pin details
      const requestObject = {
        method:'POST' ,
        body:JSON.stringify({
          fileName: imageFileUUID && imageFileUUID,
          imageCredit:imageCredit,
          title:title,
          desc:desc,
          email:email,
          newsletter:newsletter,
          x:x,
          y:y
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }

      fetch(`${InitData.api}/new-pin`, requestObject)
        .then(res => res.json())
        .then((res) => {
          if(res.update){
            this.props.closeSidebar()
            this.props.pinSubmitted()
          }
        })
        .catch(err => console.log(err))
    }
  }

  checkFormValid(){
    return true
  }

  render(){

    const {
      data
    } = this.props
    const {
      displayImage
    } = this.state
    return (
      <div className={`edit-mode-wrapper`}>
        <div className={`${displayImage && 'has-image'} edit-mode-image`} onClick={() => displayImage || this.upload.current.click()}>
          {
            displayImage &&
            <Fragment>
              <img src={displayImage}/>
              <Icon name="close-circle-outline" action={()=>this.setState({
                displayImage:null
              })}/>
            </Fragment>
          }
          {
            !displayImage &&
            <Fragment>
              <input 
                type="file"
                ref={this.upload}
                style={{display: 'none'}}
                onChange={(e)=>this.selectFile(e)}/>
                <span>upload image</span>
              <Icon name="add-circle-outline"/>
            </Fragment>
          }
        </div>
        <div className="edit-mode-input edit-mode-name">
          <span>Enter credit for image</span>
          <input type="text" onChange={(e)=>this.updateInput(e,'imageCredit')}/>
        </div>
        <div className="edit-mode-input edit-mode-name">
          <span>Enter title for pin</span>
          <input type="text" onChange={(e)=>this.updateInput(e,'title')}/>
        </div>
        <div className="edit-mode-input edit-mode-desc">
          <span>Add a description</span>
          <textarea onChange={(e)=>this.updateInput(e,'desc')}/>
        </div>
        <div className="edit-mode-input edit-mode-name">
          <span>Enter your email address</span>
          <input type="text" onChange={(e)=>this.updateInput(e,'email')}/>
        </div>
        <div className="edit-mode-input edit-mode-name">
          <span>Sign up to newsletter?</span>
          <input 
            type="checkbox"
            checked={this.state.newsletter}
            onChange={(e)=>this.updateNewsletter(e)}/>
        </div>
        <div className="button-wrapper">
          <button onClick={()=>this.submitPin()}>Submit Pin</button>
        </div>
      </div>
    )
  }
}

export default EditModeSidebar