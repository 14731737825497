import React, { Component } from "react";
import queryString from "qs";

import InitData from "../data/InitData";

import Pin from "./Pin";

class AdminLayer extends Component {
  constructor(props) {
    super(props);

    this.viewport = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    this.map = {
      mapX: 0,
      mapY: 0,
      imgWidth: InitData.imgWidth,
      imgHeight: InitData.imgHeight,
      zoomLevel: InitData.zoomLevel,
      scaleFactor: InitData.scaleFactor,
    };
    this.state = {
      colour: null,
      pins: [],
      updated: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.viewport = {
        height: window.innerHeight,
        width: window.innerWidth,
      };
    });
    //get unvalidated pins
    this.getPinsAPI();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adminUpdate !== this.props.adminUpdate) {
      this.getPinsAPI();
    }
  }

  getPinsAPI() {
    const query = queryString.parse(window.location.search);
    fetch(`${InitData.api}/admin/${query["?admin"]}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          colour: "#2c3e50",
          pins: res,
        });
      })
      .catch((err) => console.log(err));
  }

  getPinX(x) {
    const { zoomLevel, mapX } = this.props;
    const { imgWidth, scaleFactor } = this.map;
    const scaledMapX = x * scaleFactor ** zoomLevel + mapX - 16;
    return scaledMapX;
  }

  getPinY(y) {
    const { zoomLevel, mapY } = this.props;
    const { scaleFactor } = this.map;
    const scaledMapY = y * scaleFactor ** zoomLevel + mapY - 54;
    return scaledMapY;
  }

  expandPin(data) {
    const pins = this.state.pins.map((pin) => {
      pin.selected = false;
      if (pin.name === data.name) {
        pin.selected = true;
      }
      return pin;
    });
    this.setState({
      pins: pins,
    });
    this.props.expandAdminPin(data);
  }

  getPins() {
    const { isMouseMove } = this.props;

    return this.state.pins.map((data, index) => {
      return (
        <Pin
          mouseMove={isMouseMove}
          x={this.getPinX(data.x)}
          y={this.getPinY(data.y)}
          key={index}
          expandPin={(data) => this.expandPin(data)}
          data={data}
          colour={this.state.colour}
        />
      );
    });
  }

  render() {
    return <div className="data-layer-container">{this.getPins()}</div>;
  }
}

export default AdminLayer;
